/* ===========================================
   settings.breakpoints
   ========================================= */
.app-universe .container {
  margin-left: 2.5rem;
  margin-right: 2.5rem;
}
@media (min-width: 767px) {
  .app-universe .container {
    margin-left: 5rem;
    margin-right: 5rem;
  }
}
@media (min-width: 1024px) {
  .app-universe .container {
    margin-left: auto;
    margin-right: auto;
    width: 95.8rem;
  }
}
.app-universe .app-changelog {
  margin-top: 4rem;
  margin-bottom: 4rem;
  color: white;
}
.app-universe .app-changelog .app-changelog-section {
  border-bottom: 1px solid rgba(255, 255, 255, 0.25);
  padding: 2rem 0;
}
.app-universe .app-changelog .app-changelog-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 1rem;
}
.app-universe .app-changelog .app-changelog-header__version {
  font-size: 1.3rem;
  font-weight: 600;
  line-height: 2rem;
}
@media (min-width: 1024px) {
  .app-universe .app-changelog .app-changelog-header__version {
    font-size: 1.5rem;
  }
}
.app-universe .app-changelog .app-changelog-header__date {
  font-size: 1rem;
  line-height: 2rem;
  color: rgba(255, 255, 255, 0.5);
}
@media (min-width: 480px) {
  .app-universe .app-changelog .app-changelog-header__date {
    font-size: 1.1rem;
  }
}
@media (min-width: 1024px) {
  .app-universe .app-changelog .app-changelog-header__date {
    font-size: 1.2rem;
  }
}
.app-universe .app-changelog .app-changelog-content {
  color: rgba(255, 255, 255, 0.75);
  font-size: 1.2rem;
  line-height: 1.5;
}
@media (min-width: 480px) {
  .app-universe .app-changelog .app-changelog-content {
    font-size: 1.4rem;
  }
}
.app-universe .app-changelog .app-changelog-content ul {
  list-style-position: inside;
}
.app-universe .app-list {
  margin-bottom: 4rem;
}
.app-universe .app-list .app-list__hero {
  position: relative;
  height: 25rem;
  overflow: hidden;
  z-index: -1;
  padding-bottom: 5rem;
  margin-bottom: -5rem;
  top: 0;
  width: calc(100% + 30rem);
  left: -15rem;
  border-bottom-left-radius: 50% 25%;
  border-bottom-right-radius: 50% 25%;
}
.app-universe .app-list .app-list__hero > .app-list__hero-content {
  position: relative;
  margin: 0 15rem;
  height: inherit;
}
.app-universe .app-list .app-list__hero-image {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: -1;
}
.app-universe .app-list .app-list__hero-image img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.app-universe .app-list .app-list__title {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 3.5rem 0 0;
  text-transform: uppercase;
  color: white;
  font-size: 2.1rem;
  line-height: 1.1;
  font-weight: 900;
  text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
}
@media (min-width: 767px) {
  .app-universe .app-list .app-list__title {
    font-size: 2.8rem;
  }
}
@media (min-width: 1024px) {
  .app-universe .app-list .app-list__title {
    font-size: 3.6rem;
  }
}
.app-universe .app-list .app-list__items-wrapper-title {
  margin-top: 2rem;
  font-size: 1.5rem;
  line-height: 1.4;
  font-weight: bold;
  color: white;
}
.app-universe .app-list .app-list__items {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  margin: 0 -0.5rem;
}
@media (min-width: 767px) {
  .app-universe .app-list .app-list__items {
    margin: 0 -1rem;
  }
}
.app-universe .app-list .app-list__item-wrapper {
  flex: 0 0 100%;
  overflow: hidden;
  padding: 0.5rem;
}
@media (min-width: 767px) {
  .app-universe .app-list .app-list__item-wrapper {
    padding: 1rem;
    flex: 0 0 50%;
  }
}
.app-universe .app-list .app-list__item {
  position: relative;
  min-height: 10.5rem;
  border-radius: 0.7rem;
  background: white;
  padding: 1.2rem 2rem 1.2rem 1.2rem;
  display: flex;
  flex-direction: row;
  height: 100%;
}
.app-universe .app-list .app-list__item:hover .app-list__item-icon {
  transform: scale(1.05) rotate(-0.01turn);
  margin-top: -0.1rem;
}
.app-universe .app-list .app-list__item-icon {
  box-shadow: 0 0.2rem 1rem rgba(0, 0, 0, 0.5);
  width: 8rem;
  height: 8rem;
  border-radius: 22.5%;
  background-size: cover;
  transition: 100ms transform ease-out;
  margin-top: 0.5rem;
}
@media (min-width: 1024px) {
  .app-universe .app-list .app-list__item-icon {
    width: 10rem;
    height: 10rem;
  }
}
.app-universe .app-list .app-list__item-content {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-left: 2rem;
}
.app-universe .app-list .app-list__item-title {
  margin-top: 0.5rem;
  font-size: 1.6rem;
  line-height: 1.4;
  font-weight: bold;
  color: #014b32;
}
@media (min-width: 1024px) {
  .app-universe .app-list .app-list__item-title {
    font-size: 1.7rem;
  }
}
.app-universe .app-list .app-list__item-description {
  font-size: 1.2rem;
  line-height: 1.4;
  color: #a1a1B9;
}
@media (min-width: 1024px) {
  .app-universe .app-list .app-list__item-description {
    font-size: 1.3rem;
  }
}
.app-universe .app-list .app-list__item-link {
  text-transform: uppercase;
  color: #000000;
  font-size: 1.3rem;
  font-weight: bold;
  margin: auto 0 1rem 0;
  padding-top: 1rem;
}
@media (min-width: 1024px) {
  .app-universe .app-list .app-list__item-link {
    font-size: 1.5rem;
  }
}
.app-universe .app-list .app-list__item-platforms {
  position: absolute;
  color: #505050;
  font-style: italic;
  font-size: 1rem;
  right: 1.2rem;
  bottom: 0.2rem;
}
.app-universe__arrow {
  cursor: pointer;
  width: 1rem;
  height: 100%;
  color: white;
  padding: 0 1.2rem;
  margin: 0 1rem 0 0;
  outline: none;
  opacity: 1;
  transition: 150ms opacity ease-out;
  position: relative;
  display: flex;
}
.app-universe__arrow:before,
.app-universe__arrow:after {
  content: '';
  position: absolute;
  border-color: currentColor;
  border-style: solid;
  box-sizing: border-box;
}
.app-universe__arrow:before {
  width: 100%;
  border-width: 0.3rem 0 0 0;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}
.app-universe__arrow:after {
  width: 1.7rem;
  height: 1.7rem;
  border-width: 0 0 0.3rem 0.3rem;
  transform: translateY(-50%) rotate(45deg);
  left: 0;
  top: 50%;
}
.app-universe .app-universe__button {
  border-radius: 0.4rem;
  padding: 0 1.5rem;
  height: 3.3rem;
  line-height: 3.3rem;
  font-size: 1.3rem;
  text-transform: uppercase;
  display: inline-block;
  text-align: center;
  white-space: nowrap;
  cursor: pointer;
  user-select: none;
  transition: color 0.15s linear, background-color 0.15s linear;
  box-shadow: 0 0.2rem 1rem rgba(0, 0, 0, 0.5);
  background-color: white;
  color: black;
  font-weight: 600;
}
.app-universe .app-universe__button-container {
  display: flex;
  flex-flow: row nowrap;
  position: relative;
  height: 100%;
}
.app-universe .app-universe__button--cta {
  position: relative;
  border-radius: 0.4rem;
  background-color: #FEB700;
  padding: 0 2rem;
  height: 4rem;
  line-height: 4rem;
  font-size: 1.3rem;
  font-weight: bold;
  text-transform: uppercase;
  min-width: 12.5rem;
  display: inline-block;
  text-align: center;
  color: black;
  white-space: nowrap;
  cursor: pointer;
  user-select: none;
}
.app-universe .app-universe__button--installation-guide {
  position: relative;
  color: white;
  height: 4rem;
  line-height: 4rem;
  font-size: 1.3rem;
  font-weight: bold;
  text-transform: uppercase;
  display: inline-block;
  padding: 0 0 0 3rem;
  cursor: pointer;
  user-select: none;
}
.app-universe .app-universe__button--installation-guide--your-luck {
  text-shadow: rgba(0, 0, 0, 0.4) 0.1rem 0 1rem;
}
.app-universe .app-universe__button--installation-guide:after {
  content: "?";
  position: absolute;
  color: #6f3c77;
  background: white;
  width: 2rem;
  height: 2rem;
  top: 1rem;
  left: 0;
  line-height: 2rem;
  text-align: center;
  border-radius: 50%;
}
.ios-device .app-universe .app-universe__button--installation-guide {
  display: none;
}
.app-universe .app-universe__button--toggle {
  border-radius: 0.4rem;
  padding: 0 1.5rem;
  height: 3.3rem;
  line-height: 3.3rem;
  font-size: 1.3rem;
  font-weight: 600;
  text-transform: uppercase;
  display: inline-block;
  text-align: center;
  white-space: nowrap;
  cursor: pointer;
  user-select: none;
  transition: color 0.15s linear, background-color 0.15s linear;
  color: white;
}
.app-universe .app-universe__button--toggle.active {
  box-shadow: 0 0.2rem 1rem rgba(0, 0, 0, 0.5);
  background-color: white;
  color: black;
  font-weight: 600;
}
.app-universe .app-universe__button-flash {
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: hidden;
}
.app-universe .app-universe__button-flash:before {
  position: absolute;
  content: "";
  top: -50%;
  bottom: -50%;
  left: -200%;
  width: 3rem;
  background-color: rgba(255, 255, 255, 0.3);
  animation: appUniverseButtonFlash 1000ms linear infinite;
  transform: rotate(20deg);
}
.app-universe .app-universe__button-flash:after {
  position: absolute;
  content: "";
  top: -50%;
  bottom: -50%;
  left: -200%;
  width: 1.5rem;
  background-color: rgba(255, 255, 255, 0.3);
  animation: appUniverseButtonFlash 1000ms linear infinite;
  animation-delay: 500ms;
  transform: rotate(20deg) translateX(-2rem);
}
@keyframes appUniverseButtonFlash {
  100% {
    left: 300%;
  }
}
.app-universe .app-description {
  color: white;
}
.app-universe .app-description p {
  font-size: 1.3rem;
  margin: 0 0 0.5em;
  line-height: 1.4;
  font-weight: 400;
  text-transform: none;
}
.app-universe .app-description p:not([class]):last-child {
  margin: 0 0 1em;
}
@media (min-width: 767px) {
  .app-universe .app-description p {
    font-size: 1.5rem;
  }
}
.app-universe .app-description h1 {
  font-size: 2rem;
  line-height: 1.3;
  font-weight: 600;
  text-transform: none;
  margin: 0 0 0.2em;
}
@media (min-width: 767px) {
  .app-universe .app-description h1 {
    font-size: 2.8rem;
  }
}
.app-universe .app-description h1 ~ p {
  font-size: 1.4rem;
}
@media (min-width: 767px) {
  .app-universe .app-description h1 ~ p {
    font-size: 1.6rem;
  }
}
.app-universe .app-description h2 {
  font-size: 1.6rem;
  line-height: 1.4;
  font-weight: 600;
  text-transform: none;
  margin: 0 0 0.2em;
}
@media (min-width: 767px) {
  .app-universe .app-description h2 {
    font-size: 1.8rem;
  }
}
.app-universe .app-history {
  margin: 0 0 3rem;
  background: #eff2f8;
  border-radius: 1rem;
  padding: 2rem 2rem 2.5rem;
}
@media (min-width: 102.5rem) {
  .app-universe .app-history {
    padding: 2rem 2rem 3rem 2.5rem;
  }
}
.app-universe .app-history-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 1rem;
}
.app-universe .app-history-header .app-history-header__title {
  padding: 0.4rem 1rem 0 0;
  color: #222;
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 1.3;
}
@media (min-width: 102.5rem) {
  .app-universe .app-history-header .app-history-header__title {
    font-size: 1.7rem;
  }
}
.app-universe .app-history-header__link {
  font-size: 1.1rem;
  line-height: 2.6rem;
  height: 2.6rem;
  padding: 0 2.2rem 0 1rem;
  background: #7F4488;
  color: white;
  border-radius: 1.3rem;
  text-decoration: none;
  position: relative;
}
@media (min-width: 102.5rem) {
  .app-universe .app-history-header__link {
    font-size: 1.3rem;
    line-height: 3rem;
    height: 3rem;
    border-radius: 1.5rem;
    padding: 0 2.7rem 0 1.7rem;
  }
}
.app-universe .app-history-header__link:after {
  content: "";
  position: absolute;
  right: 1rem;
  top: 1.1rem;
  border: solid white;
  border-width: 0 0.1rem 0.1rem 0;
  display: inline-block;
  padding: 0.2rem;
  transform: rotate(-45deg);
}
@media (min-width: 102.5rem) {
  .app-universe .app-history-header__link:after {
    border-width: 0 0.1rem 0.1rem 0;
    padding: 0.3rem;
    top: 1.2rem;
    right: 1.2rem;
  }
}
.app-universe .app-history-header__version {
  font-size: 1.3rem;
  font-weight: 600;
  line-height: 2rem;
  color: #777;
}
@media (min-width: 102.5rem) {
  .app-universe .app-history-header__version {
    font-size: 1.5rem;
  }
}
.app-universe .app-history-header__date {
  color: #777;
  font-size: 1rem;
  line-height: 2rem;
}
@media (min-width: 50rem) {
  .app-universe .app-history-header__date {
    font-size: 1.1rem;
  }
}
@media (min-width: 102.5rem) {
  .app-universe .app-history-header__date {
    font-size: 1.2rem;
  }
}
.app-universe .app-history-content {
  color: #222;
  font-size: 1.2rem;
  line-height: 1.5;
}
@media (min-width: 50rem) {
  .app-universe .app-history-content {
    font-size: 1.4rem;
  }
}
.app-universe .app-history-content ul {
  list-style-position: inside;
}
.app-universe .phone-tablet-steps {
  width: 100%;
  margin-top: 0.5rem;
  margin-bottom: 3rem;
}
.app-universe .phone-tablet-steps .phone-tablet-steps__container {
  display: flex;
  flex-wrap: nowrap;
  position: relative;
  width: 100%;
}
.app-universe .phone-tablet-steps .phone-tablet-steps__wrapper {
  width: 100%;
  display: flex;
}
.app-universe .phone-tablet-steps .phone-tablet-steps__step {
  pointer-events: none;
  flex: 0 0 20rem;
  width: 20rem;
  margin: 0 1rem;
  border-radius: 1rem;
  box-shadow: 0 0.2rem 1rem rgba(0, 0, 0, 0.5);
  background-repeat: no-repeat;
  background-color: white;
  background-position: center;
  background-size: cover;
  outline: none;
}
.app-universe .phone-tablet-steps .phone-tablet-steps__step.tablet {
  flex: 0 0 37rem;
  width: 37rem;
  height: 29rem;
}
.app-universe .phone-tablet-steps__container .slick-list {
  padding: 2rem 0;
}
.app-universe .phone-tablet-steps__container .slick-track {
  left: 2.5rem;
}
@media (min-width: 767px) {
  .app-universe .phone-tablet-steps__container .slick-track {
    left: 5rem;
  }
}
@media (min-width: 1024px) {
  .app-universe .phone-tablet-steps__container .slick-track {
    left: calc((100% - 95.8rem)/2);
  }
}
.app-installation-guide__version-selector {
  position: relative;
  z-index: 15;
  padding: 0 2.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media (min-width: 767px) {
  .app-installation-guide__version-selector {
    padding: 0 5rem;
  }
}
@media only screen and (min-width: 1024px) {
  .app-installation-guide__version-selector {
    flex-direction: row;
    justify-content: center;
  }
}
.app-installation-guide__version-selector .selector-wrapper {
  display: flex;
  flex-direction: column;
  user-select: none;
  width: 100%;
  margin-bottom: 1.5rem;
}
@media only screen and (min-width: 1024px) {
  .app-installation-guide__version-selector .selector-wrapper {
    flex-direction: row;
    width: auto;
    margin-left: 2rem;
  }
  .app-installation-guide__version-selector .selector-wrapper:first-child {
    margin-left: 0;
  }
}
.app-installation-guide__version-selector .selector-wrapper .selector-label {
  color: rgba(255, 255, 255, 0.8);
  font-weight: 600;
  line-height: 1.3;
  font-size: 1.3rem;
  margin: 0 0 0.5rem;
}
@media only screen and (min-width: 1024px) {
  .app-installation-guide__version-selector .selector-wrapper .selector-label {
    margin: 1rem 1rem 1rem 0;
  }
}
.app-installation-guide__version-selector .selector-wrapper .options-wrapper {
  display: inline-block;
  position: relative;
  box-shadow: 0 0.2rem 1rem rgba(0, 0, 0, 0.5);
}
.app-installation-guide__version-selector .selector-wrapper .options-wrapper .options-placeholder-wrapper {
  background-color: white;
  border-radius: 0.4rem;
  position: relative;
  overflow: hidden;
  padding: 0 3rem 0 1.5rem;
  cursor: pointer;
  height: 3.5rem;
  line-height: 3.5rem;
}
.app-installation-guide__version-selector .selector-wrapper .options-wrapper .options-placeholder-wrapper:hover {
  background-color: #eee;
}
.app-installation-guide__version-selector .selector-wrapper .options-wrapper .options-placeholder-wrapper .placeholder-text {
  color: black;
  font-weight: 600;
  line-height: 1;
  font-size: 1.4rem;
}
.app-installation-guide__version-selector .selector-wrapper .options-wrapper .options-placeholder-wrapper .placeholder-text:after {
  content: "";
  border-width: 0.2rem 0.2rem 0 0;
  border-style: solid;
  border-color: black;
  display: inline-block;
  box-sizing: border-box;
  transform: translateY(-50%) rotate(135deg);
  transition: transform 150ms ease-out;
  position: absolute;
  width: 0.7rem;
  height: 0.7rem;
  right: 1.5rem;
  top: 1.6rem;
}
.app-installation-guide__version-selector .selector-wrapper .options-wrapper .options-list-wrapper {
  position: absolute;
  z-index: 10;
  background-color: white;
  border-radius: 0.4rem;
  left: 0;
  box-shadow: 0 0.2rem 1rem rgba(0, 0, 0, 0.5);
  width: 22.5rem;
  margin-top: 1rem;
}
.app-installation-guide__version-selector .selector-wrapper .options-wrapper .options-list-wrapper:before {
  content: '';
  border-radius: 0.2rem;
  background-color: white;
  opacity: 0;
  position: absolute;
  top: 0;
  transform: translateY(30%) rotate(45deg);
  width: 1.2rem;
  height: 1.2rem;
  left: 1.3rem;
}
.app-installation-guide__version-selector .selector-wrapper .options-wrapper .options-list-wrapper .option-item-wrapper {
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  position: relative;
  cursor: pointer;
}
.app-installation-guide__version-selector .selector-wrapper .options-wrapper .options-list-wrapper .option-item-wrapper .option-item-text {
  color: black;
  font-weight: 600;
  line-height: 1;
  display: block;
  border-top: 1px solid #ddd;
  font-size: 1.4rem;
  padding: 1.4rem 3.5rem 1.4rem 1.4rem;
}
.app-installation-guide__version-selector .selector-wrapper .options-wrapper .options-list-wrapper .option-item-wrapper .option-item-icon {
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 50%;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  position: absolute;
  width: 1.8rem;
  height: 1.8rem;
  top: 1.3rem;
  right: 1.4rem;
}
.app-installation-guide__version-selector .selector-wrapper .options-wrapper .options-list-wrapper .option-item-wrapper .option-item-icon:before {
  content: '';
  background-color: #7F4488;
  opacity: 0;
  border-radius: 50%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: scale(0);
}
.app-installation-guide__version-selector .selector-wrapper .options-wrapper .options-list-wrapper .option-item-wrapper .option-item-icon:after {
  content: '';
  width: 35%;
  padding-top: 20%;
  opacity: 0;
  border-width: 0 0 0.2rem 0.2rem;
  border-style: solid;
  border-color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -65%) rotate(-45deg);
}
.app-installation-guide__version-selector .selector-wrapper .options-wrapper .options-list-wrapper .option-item-wrapper:first-child .option-item-text {
  border-top: none;
}
.app-installation-guide__version-selector .selector-wrapper .options-wrapper .options-list-wrapper .option-item-wrapper.selected .option-item-icon {
  border: 1px solid #7F4488;
}
.app-installation-guide__version-selector .selector-wrapper .options-wrapper .options-list-wrapper .option-item-wrapper.selected .option-item-icon:before {
  opacity: 1;
  transform: scale(1);
}
.app-installation-guide__version-selector .selector-wrapper .options-wrapper .options-list-wrapper .option-item-wrapper.selected .option-item-icon:after {
  opacity: 1;
}
.app-installation-guide__version-selector .selector-wrapper .options-wrapper.active .options-placeholder-wrapper .placeholder-text:after {
  transform: translateY(0) rotate(-45deg);
}
.app-installation-guide__version-selector .selector-wrapper .options-wrapper.active .options-list-wrapper:before {
  transform: translateY(-30%) rotate(45deg);
  opacity: 1;
}
.app-installation-guide__version-selector .selector-wrapper .options-wrapper.active .options-list-wrapper .option-item-wrapper {
  max-height: 4.2rem;
  opacity: 1;
}
.app-installation-guide__version-selector .selector-wrapper .options-wrapper.active .options-list-wrapper .option-item-wrapper:hover {
  background-color: #eee;
}
