.region-quick .seo-text-container,
.region-avalon-quick .seo-text-container,
.region-quick footer .info-bar,
.region-avalon-quick footer .info-bar {
  background: #623569;
}
.region-quick .global-footer,
.region-avalon-quick .global-footer {
  border: none;
}
