@import '../Variables/breakpoints.less';
@import '../Variables/colors.less';

.avalon-area {

  .game-list-spot-component {
    @el-height-mobile: 4rem;
    @el-height-tablet: 4.8rem;

    .drop-down {
      user-select: none;
      position: relative;
      z-index: 15;
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      align-items: center;
      font-size: 1.6rem;
      font-weight: 600;
      color: @color-white;
      margin-bottom: 1rem;
      flex: 0 0 calc(50% - 1rem);
      height: @el-height-mobile;

      &.active-filter {
        .drop-down__content {
          border: 0.2rem solid @color-yellow-orange;
        }

        .drop-down__label {
          color: @color-yellow-orange;
        }

        &:after {
          border-color: @color-yellow-orange;
        }
      }

      &:after {
        content: ' ';
        position: absolute;
        right: 1.6rem;
        top: 1.3rem;
        border: solid @color-white;
        border-width: 0 0.2rem 0.2rem 0;
        display: inline-block;
        padding: 0.4rem;
        transform: rotate(45deg);
        margin-bottom: 0.4rem;
      }

      &.open {
        &:after {
          content: 'GEM';
          right: 0;
          top: 0;
          height: @el-height-mobile;
          display: flex;
          align-items: center;
          border: none;
          padding: 0 1.2rem 0 1rem;
          font-size: 1.4rem;
          color: @color-yellow-orange;
          transform: rotate(0);
          cursor: pointer;
        }
      }

      .drop-down__content {
        position: absolute;
        left: 0;
        box-shadow: 0 0 0.4rem 0 rgba(0, 0, 0, 0.5);
        border-radius: 0.4rem;
        background-color: @color-light-eggplant;
        width: 100%;
        border: 0.2rem solid transparent;
      }

      .drop-down__label {
        padding-left: 2rem;
        padding-right: 3rem;
        height: @el-height-mobile;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        cursor: pointer;
      }

      .drop-down__menu {
        display: none;
        margin-top: 0.5rem;

        .drop-down__menu-item {
          height: 5.2rem;
          margin: 0 1rem 0 1.6rem;
          font-weight: normal;
          border-bottom: 0.1rem solid @color-grape;
          display: flex;
          align-items: center;

          &:last-child {
            border-bottom: none;
          }

          .drop-down__menu-item-container {
            display: flex;
            align-items: center;
            height: 4rem;
            position: relative;
            cursor: pointer;
            width: 100%;
            padding: 0 0 0 2.5rem;
            font-size: 1.3rem;
            line-height: 1.3;

            @media(min-width: @breakpoint-medium) {
              font-size: 1.5rem;
            }

            input {
              position: absolute;
              opacity: 0;
              cursor: pointer;
              height: 0;
              width: 0;
            }

            .checkmark {
              position: absolute;
              top: 1.1rem;
              left: 0;
              height: 1.8rem;
              width: 1.8rem;
              background-color: @color-white;
              border-radius: 0.4rem;

              /* Create the checkmark/indicator (hidden when not checked) */
              &:after {
                content: '';
                position: absolute;
                display: none;
              }
            }

            /* When the checkbox is checked, add a blue background */
            input:checked ~ .checkmark {
              background-color: @color-yellow-orange;
            }

            input:checked + span {
              color: @color-yellow-orange;
            }

            /* Show the checkmark when checked */
            input:checked ~ .checkmark:after {
              display: block;
            }

            /* Style the checkmark/indicator */
            .checkmark:after {
              left: 0.6rem;
              top: 0.2rem;
              width: 0.6rem;
              height: 1.1rem;
              border: solid @color-grape;
              border-width: 0 0.3rem 0.3rem 0;
              transform: rotate(45deg);
            }
          }
        }
      }

      &.open {
        .drop-down__content,
        &:hover .drop-down__content {
          border: 0.2rem solid transparent;
        }

        .drop-down__menu {
          display: block;
        }
      }
    }

    @media only screen and (min-width: @breakpoint-small) {
      .drop-down {
        height: @el-height-tablet;
        flex: 0 0 21rem;
        margin: 0 2rem 0 0;

        &:hover {
          .drop-down__content {
            border: 0.2rem solid @color-yellow-orange;
          }
        }

        .drop-down__menu .drop-down__menu-item:hover {
          color: @color-yellow-orange;
        }

        &:after {
          right: 2.2rem;
          top: 1.7rem;
        }

        &.open {
          &:after {
            height: @el-height-tablet;
          }
        }

        .drop-down__label {
          height: @el-height-tablet;
        }
      }
    }

    @media only screen and (min-width: @breakpoint-medium) {
      .drop-down {
        font-size: 1.8rem;
        flex: 0 0 20rem;
        margin: 0 2rem 0 0;
      }
    }
  }
}


.avalon-area {

  .quick-games.search-games-component {
    @el-height-mobile: 4rem;
    @el-height-tablet: 4.8rem;

    .dropdowns-container {
      display: flex;
      gap: 1rem;
      opacity: 0;
      transition: opacity .5s;
      @media only screen and (min-width: @breakpoint-small) {
        opacity: 1;
      }
    }

    .drop-down {
      user-select: none;
      position: relative;
      z-index: 15;
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      align-items: center;
      font-size: 1.6rem;
      font-weight: 600;
      color: @color-text;
      margin-bottom: 1rem;
      flex: 0 0 calc(50% - 1rem);
      height: @el-height-mobile;

      .filter-bar__label {
        position: absolute;
        left: 0;
        top: .2rem;
        width: 100%;
        height: 100%;
        user-select: none;
        cursor: pointer;
        display: inline-block;
        background-color: @color-dark-eggplant;
        padding: .8rem 1.2rem 0 2.2rem;
        font-size: 1.5rem;
        color: @color-white;
        border-radius: 1.5rem;
        z-index: 10;
        @media only screen and (min-width: @breakpoint-small) {
          padding: 1.2rem 1.2rem 0 2.2rem;
        }

        &.filter-bar__label-close {
          input {
            display: none;
          }

          &:before,
          &:after {
            position: absolute;
            right: 2.2rem;
            content: ' ';
            height: 1.3rem;
            width: 0.2rem;
            top: 1.4rem;
            background-color: @color-white;
            transform: rotate(45deg);
            @media only screen and (min-width: @breakpoint-small) {
              top: 1.8rem;
            }
          }

          &:after {
            transform: rotate(-45deg);
          }
        }
      }

      .drop-down__label {
        color: @color-white;
      }

      &:after {
        content: ' ';
        cursor: pointer;
        position: absolute;
        z-index: 5;
        right: 1.6rem;
        top: 1.4rem;
        border: solid @color-white;
        border-width: 0 0.2rem 0.2rem 0;
        display: inline-block;
        padding: 0.4rem;
        transform: rotate(45deg);
        transition: transform .25s ease-in-out;
        margin-bottom: 0.4rem;
      }

      &.open:after {
        top: 2.2rem;
        transform: rotate(226deg);
      }

      &.open {
        .drop-down__label {
          background-color: @color-dark-eggplant;
        }
      }

      .drop-down__content {
        position: absolute;
        left: 0;
        width: 100%;
        border: 0.2rem solid transparent;
      }

      .drop-down__label {
        padding-left: 2rem;
        padding-right: 3rem;
        background-color: @color-light-eggplant;
        border-radius: 1.5rem;
        height: @el-height-mobile;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        position: relative;
        z-index: 2;
        cursor: pointer;

        &:hover {
          background-color: @color-dark-eggplant;
        }
      }

      .drop-down__menu {
        display: none;
        position: relative;
        top: -1.5rem;
        padding-top: 1.5rem;
        background-color: @color-white;
        border: .1rem solid @color-white;
        border-radius: 0 0 1.5rem 1.5rem;
        z-index: 1;

        .drop-down__menu-item {
          height: 5.2rem;
          margin: 0 1rem 0 1.6rem;
          font-weight: normal;
          display: flex;
          align-items: center;

          .drop-down__menu-item-container {
            display: flex;
            align-items: center;
            height: 4rem;
            position: relative;
            cursor: pointer;
            width: 100%;
            padding: 0;
            font-size: 1.3rem;
            font-weight: 700;
            line-height: 1.3;

            @media(min-width: @breakpoint-medium) {
              font-size: 1.5rem;
            }

            input {
              display: none;
            }

            .checkmark {
              display: none;
            }
          }
        }
      }

      &.open {
        .drop-down__menu {
          display: block;
          border-color: @color-dark-eggplant;
        }
      }
    }

    @media only screen and (min-width: @breakpoint-small) {
      .drop-down {
        height: @el-height-tablet;
        flex: 0 0 19rem;
        margin: 0 1rem 0 0;

        .drop-down__menu .drop-down__menu-item:hover {
          color: @color-yellow-orange;
        }

        &:after {
          right: 2.2rem;
          top: 1.7rem;
        }

        .drop-down__label {
          height: @el-height-tablet;
        }
      }
    }

    @media only screen and (min-width: @breakpoint-medium) {
      .drop-down {
        font-size: 1.8rem;
        flex: 0 0 19rem;
        margin: 0 2rem 0 0;
      }
    }
  }
}