@import '../Variables/breakpoints.less';

.avalon-area .container {
  margin-left: 1.6rem;
  margin-right: 1.6rem;
  width: calc(~"100% - 3.2rem");

  @media only screen and (min-width: @breakpoint-small) {
    width: calc(~"@{breakpoint-small} - 40px");
    margin-left: auto;
    margin-right: auto;
  }

  @media only screen and (min-width: @breakpoint-smallmedium) {
    width: calc(~"@{breakpoint-smallmedium} - 50px");
  }

  @media only screen and (min-width: @breakpoint-medium) {
    width: calc(~"@{breakpoint-medium} - 50px");
  }
}
// temp override to på deleted after quick digital release week 26 / 2023
.avalon-area .featured-games-spot .container, .avalon-area .game-list-spot-component .container {
  @media only screen and (min-width: @breakpoint-smallmedium) {
    width: calc(~"@{breakpoint-small} - 40px");
    margin-left: auto;
    margin-right: auto;
  }
  @media only screen and (min-width: @breakpoint-medium) {
    width: calc(~"@{breakpoint-medium} - 50px");
  }
}
