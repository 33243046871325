@import '../Variables/breakpoints.less';
@import '../Variables/colors.less';

.avalon-area .quick-games {
  .game-section {
    margin: 3rem 0;

    @media(min-width: @breakpoint-small) {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 5rem;
      margin: 6rem 0 6rem;
    }

    .game-section__images {
      width: 100%;
      max-width: 48rem;
      height: 100%;
      position: relative;

      @media(min-width: @breakpoint-small) {
        width: 48rem;
        order: 2;
        margin-left: auto;
      }

      .game-section__additional-images--position-1 {
        left: 15%;
        top: 15%;
      }

      .game-section__additional-images--position-2 {
        left: 1%;
        bottom: 30%;
      }

      .game-section__additional-images--position-3 {
        left: 10%;
        bottom: 6%;
      }

      .game-section__additional-images--position-4 {
        right: 10%;
        top: 10%;
      }

      .game-section__additional-images--position-5 {
        right: -1%;
        bottom: 17%;
      }
    }

    .game-section__text {
      margin-top: 3rem;
      text-align: center;

      @media(min-width: @breakpoint-small) {
        text-align: left;
        max-width: 50%;
      }

      .title {
        color: @color-white;
        font-size: 2.4rem;
        font-family: "Helium";
        font-weight: 500;
        text-transform: none;

        @media(min-width: @breakpoint-small) {
          order: 1;
          font-size: 4rem;
        }
      }

      .text {
        color: @color-white;
        font-size: 1.4rem;

        @media(min-width: @breakpoint-small) {
          order: 1;
          font-size: 1.6rem;
        }
      }
    }

    .game-section__image {
      width: 100%;
      max-width: 48rem;
      border-radius: 4.5rem;
      box-shadow: 0rem 0rem 2rem rgba(0, 0, 0, 0.3);
    }

    .game-section__additional-images {
      display: none;

      @media(min-width: @breakpoint-small) {
        display: block;
        height: 4rem;
        width: 4rem;
        position: absolute;
      }
    }
  }

  .game-section--medium {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    @media(min-width: @breakpoint-small) {
      flex-direction: row;
    }

    .game-section__image-button {
      position: absolute;
      bottom: 20px;
      display: flex;
      width: 100%;
      justify-content: center;
    }
  }

  .game-section--mega {
    display: flex;
    justify-content: center;

    .game-section__image-button {
      position: absolute;
      bottom: 20px;
      display: flex;
      width: 100%;
      justify-content: center;
    }

    .game-section__images {
      width: 100%;

      @media(min-width: @breakpoint-small) {
        width: 85%;
        max-width: 85%;
        margin-right: auto;
      }
    }

    .game-section__image {
      max-width: 100%;
      max-height: 40rem;
      object-fit: cover;
    }

    .game-section__text {
      position: absolute;
      text-align: center;
      flex-basis: 45%;

      @media(min-width: @breakpoint-small) {
        margin-top: -150px;
      }
    }
  }
}
