@import '../Variables/breakpoints.less';
@import '../Variables/colors.less';

.avalon-area .quick-games {
  .mega-cards {
    display: flex;
    flex-wrap: nowrap;
    margin: 0;
    padding-top: 1.2rem;

    @media only screen and (min-width: @breakpoint-small) {
      margin: 0 auto;
    }

    @media only screen and (min-width: @breakpoint-medium) {
      padding-top: 1.6rem;
    }
  }

  .mega-cards__wrapper {
    @media only screen and (min-width: @breakpoint-small) {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
    }
  }

  .mega-cards__wrapper--center {
    justify-content: center;
  }

  // siper-active is a class set by FeaturedGames.js to indicate that we have js powered swiper active
  .swiper-active .mega-cards__wrapper--center {
    justify-content: flex-start;
  }

  .mega-cards .card {
    cursor: pointer;
    height: 24rem;
    margin: 0 0.8rem 1rem 1.6rem;
    flex: 0 0 25.6rem;
    width: 25.6rem;

    &:hover {

      .card__content {
        transform: translateY(0);
      }

      .mega-card__image {
        transform: scale(1) translateY(-19px);
      }
    }

    @media only screen and (min-width: @breakpoint-small) {
      display: flex;
      width: 23.2rem;
      flex-basis: 23.2rem;
      height: 24rem;
      margin: 0 1.6rem 2rem 0;

      &:nth-child(3n) {
        margin-right: 0;
      }

      &:last-child {
        margin-right: 0;
      }

    }

    @media only screen and (min-width: @breakpoint-medium) {
      width: 35.8rem;
      flex-basis: 35.8rem;
      height: 36.8rem;
      margin-right: 5rem;
      margin-bottom: 3rem;
    }

    .card__labels {
      margin: 0 0 0.8rem;

      .card__label {
        display: inline-block;
        font-size: 1.4rem;
        background: @color-white;

        @media only screen and (min-width: @breakpoint-small) {
          font-size: 1.1rem;
        }

        @media only screen and (min-width: @breakpoint-medium) {
          font-size: 1.6rem;
        }

        &.card__label--price {
          background-color: @color-yellow-orange;
          padding: 0.3rem 0.8rem;
          border-radius: 0.3rem 0.2rem 0.2rem 0.3rem;
          margin-right: 0.2rem;
        }

        &.card__label--prize {
          padding: 0.3rem 1.3rem 0.3rem 0.8rem;
          border-radius: 0.3rem 1.5rem 1.5rem 0.3rem;
        }
      }
    }

    .card__attribute--new {
      top: -1.5rem;
      right: 1rem;
      height: 5rem;

      @media only screen and (min-width: @breakpoint-medium) {
        top: -2rem;
        right: 0.8rem;
        height: 7rem;
      }
    }

    .card__attribute--popular {
      top: -0.3rem;
      left: -0.3rem;
      width: 6.4rem;
      height: 6.4rem;

      @media only screen and (min-width: @breakpoint-medium) {
        top: -0.45rem;
        left: -0.45rem;
        width: 12rem;
        height: 12rem;
      }
    }

    .card:hover .card-image__background {
      @media only screen and (min-width: @breakpoint-medium) {
        transform: scale(1.02);
      }
    }

    .card:hover .card-image__overlay {
      @media only screen and (min-width: @breakpoint-medium) {
        transform: scale(0.9) translateY(-30px);
      }
    }

    .card__content {
      @media only screen and (min-width: @breakpoint-medium) {
        padding: 1rem 1.6rem 0;
        transform: translateY(70px);
      }
    }

    .card__description {
      font-size: 1.6rem;
      line-height: 1.3;
      display: none;
      margin: 0.3rem 0 1rem;

      @media only screen and (min-width: @breakpoint-medium) {
        display: flex;
      }
    }
  }

  .mega-card__images {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
