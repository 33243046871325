@import '../Variables/breakpoints.less';
@import '../Variables/colors.less';

/**
 * Swiper style overrides to adjust it to quick content
 */

@media only screen and (max-width: @breakpoint-small) {
  .swiper-container {
    padding: 1rem 0 0 0;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }

  // Important is required because otherwise swiper.js will override the margin
  .swiper-wrapper {
    margin-bottom: 4rem;
  }

  .swiper-slide {
    width: auto;
  }

  .swiper-pagination-bullet {
    background: @color-yellow-orange;
  }

  .swiper-pagination-bullet-active {
    height: 1rem;
    width: 1rem;
    background: @color-yellow-orange;
  }

  .swiper-scrollbar {
    overflow: hidden;
    background: @color-yellow-orange-faded;
    width: 50%;
  }

  .swiper-container-horizontal > .swiper-scrollbar {
    height: 1rem;
    max-width: 50%;
    transform: translateX(50%);
    bottom: 0;
    margin: 0 0 2rem;
  }

  .swiper-scrollbar-drag {
    position: relative;
    height: 3rem;
    width: 3rem;
    background: @color-yellow-orange;
    margin-top: -1rem;
    z-index: 100;
  }
}
