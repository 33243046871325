﻿@import (reference) "Mixins/_mixins.less";

.overlay-region-avalon-quick,
.overlay-region-quick {
  margin: 100px auto 60px auto;
  width: 980px;
  min-height: 200px;
  background: #3e2948 url('/Components/DanskeSpil/Project/Design/Graphics/regions/quick/overlay-background-quick.jpg') 0 0 no-repeat;
  .cm-corners-all(1px);
  .cm-box-shadow(0px, 10px, 15px, 2px, @rgba-black-075);
  position: relative;
  left: -10px;

  .header {
    height: 71px;
    position: relative;

    .title {
      margin: 0;
      position: absolute;
      top: 25px;
      left: 31px;
      color: #fff;
      .cm-font-size(2.5);
      line-height: 28px;
      .cm-font-ds-black();
    }

    .close {
      position: absolute;
      top: 13px;
      right: 30px;
      width: 42px;
      height: 43px;
      background-color: @quick-purple;
      border: 1px solid @quick-dark-purple;
      .cm-corners-all(1px);
      .cm-box-shadow(0px, 2px, 3px, 1px, @rgba-black-025);
      cursor: pointer;

      &:hover {
        text-decoration: none;
      }

      &:before {
        content: "";
        display: block;
        height: 1px;
        background: #9e71a5;
        margin-right: 1px;
        margin-left: 1px;
      }

      &:after {
        content: "";
        position: absolute;
        top: 12px;
        left: 12px;
        width: 18px;
        height: 19px;
        .ds-global-sprite(-300px, -100px);
      }
    }
  }

  .column-wrapper {
    .cm-clearfix();
    width: 100%;
    padding: 0 30px;

    .primary-column {
      float: right;
      width: 72.17391304%;

      &.full-size {
        width: 100%;
      }

      .article {
        background: #fff;
        .cm-corners-all(3px);
        .cm-box-shadow();
        min-height: 300px;
        padding: 23px 30px 60px 32px;
        .cm-clearfix();

        .headline {
          text-shadow: none;
          color: @quick-gray-text;
          .cm-font-size(3.0);
          line-height: 33px;
          .cm-font-ds-black();
          margin-bottom: 12px;
        }

        .image {
          float: right;
          margin-left: 40px;
          margin-bottom: 20px;
        }

        .html-text {
          color: @quick-gray-text;
          .cm-font-size(1.5);
          line-height: 18px;
          .cm-font-ds-book();

          p {
            line-height: inherit;
            margin-bottom: 20px;

            &:last-child {
              margin: 5px;
            }
          }
        }
      }
    }

    .aside-column {
      float: left;
      width: 23.47826087%;
    }

    .aside-navigation {
      background-color: @quick-purple;
      .cm-corners-all(3px);
      overflow: hidden;
      width: 100%;
      margin-bottom: 15px;

      .item {
        list-style: none;
        margin: 0;
        display: block;

        a {
          display: block;
          color: #fff;
          .cm-font-size(1.5);
          line-height: 18px;
          .cm-font-ds-black();
          padding: 16px 30px 16px 20px;
          border-bottom: 1px solid #66306e;
          position: relative;

          &:after {
            content: "";
            position: absolute;
            right: 10px;
            top: 14px;
            width: 12px;
            height: 21px;
            .ds-global-sprite(-300px, -200px);
            .cm-opacity(.4);
          }

          &:hover {
            text-decoration: none;
            background-color: @quick-dark-purple;

            &:after {
              .cm-opacity(1);
            }
          }

          &.selected {
            .cm-font-ds-bold();
            background-color: @quick-dark-purple;

            &:after {
              .cm-opacity(1);
            }
          }
        }

        &:last-child {
          a {
            border-bottom: none;
          }
        }
      }
    }
  }

  .footer {
    position: relative;
    height: 75px;
    width: 100%;

    &:before {
      content: "";
      .ds-global-sprite(-100px, -100px);
      position: absolute;
      top: 29px;
      left: 30px;
      width: 145px;
      height: 23px;
    }

    &:after {
      content: "";
      .quick-sprite(-400px, -400px);
      position: absolute;
      top: 30px;
      right: 25px;
      width: 72px;
      height: 21px;
    }

    strong {
      .cm-visuallyhidden();
    }
  }
}
