@import '../Variables/breakpoints.less';
@import '../Variables/colors.less';

.avalon-area {

  .search-spot {
    display: flex;
    margin-top: 2rem;
    padding-top: 2rem;
    overflow: hidden;
    align-items: center;
    justify-content: center;
    position: relative;
  }

  .search-spot__input-container {
    margin-left: auto;
    position: relative;
    top: .2rem;
    margin-bottom: 0.2rem;
    height: 5.2rem;
    right: -100%;
    opacity: 0;
    transition: right .2s ease, opacity .6s ease-in;

    &.visible {
      right: .2rem;
      opacity: 1;
    }

    @media(min-width: @breakpoint-small) {
      right: .2rem;
      opacity: 1;
    }

    input {
      -webkit-appearance: none;
      box-sizing: border-box;
      width: 100%;
      height: 100%;
      border-radius: 3.2rem;
      box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.5);
      font-size: 1.6rem;
      color: @color-text;
      border: none;
      padding: 0 3.5rem 0 4.5rem;

      &::placeholder {
        color: @color-text;
      }
    }
    .magnifying-glass {
      position: absolute;
      top: 1.6rem;
      left: 1.6rem;
      height: 2rem;
      cursor: pointer;
    }
  }

  .search-spot__toggle {
    display: block;
    position: absolute;
    right: .3rem;
    cursor: pointer;
    height: 5.2rem;

    &.hidden {
      display: none;
    }

    @media(min-width: @breakpoint-small) {
      display: none;
    }
  }

}
