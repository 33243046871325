@import '../Variables/breakpoints.less';
@import '../Variables/colors.less';

.avalon-area {
  .game-list-spot-component {
    @el-height-mobile: 4rem;
    @el-height-tablet: 4.8rem;

    .filter-bar__content {
      background: @color-grape;
      padding: 1.6rem 0;

      .container {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .label,
        input {
          height: @el-height-mobile;
        }

        .label {
          display: none;
          color: @color-white;
          font-size: 1.4rem;
          padding: 0 2rem 0 0.1rem;
        }

        .input-wrapper {
          flex: 1 0 100%;
          position: relative;

          input {
            -webkit-appearance: none;
            box-sizing: border-box;
            width: 100%;
            border-radius: 0.4rem;
            box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.5);
            font-size: 1.6rem;
            color: #7f4488;
            border: none;
            padding: 0 3.5rem 0 1.5rem;

            &::placeholder {
              color: @color-placeholder;
            }
          }

          .magnifying-glass {
            position: absolute;
            top: 0.9rem;
            right: 1.1rem;
            height: 2rem;
          }
        }
      }
    }

    .filter-bar__labels {
      .filter-bar__label {
        user-select: none;
        cursor: pointer;
        display: inline-block;
        margin: 1rem 1rem 0 0;
        background-color: @color-grape;
        padding: 0.6rem 1.2rem 0.6rem 3.2rem;
        font-size: 1.5rem;
        color: @color-white;
        border-radius: 0.5rem;
        position: relative;

        &.filter-bar__label-close {
          border: 0.1rem solid transparent;

          input {
            display: none;
          }

          &:before,
          &:after {
            position: absolute;
            left: 1.5rem;
            content: ' ';
            height: 1.3rem;
            width: 0.2rem;
            top: 1.2rem;
            background-color: @color-white;
            transform: rotate(45deg);
          }

          &:after {
            transform: rotate(-45deg);
          }

          &:hover {
            border: 0.1rem solid @color-yellow-orange;
          }
        }
      }
    }

    @media only screen and (min-width: @breakpoint-small) {
      .filter-bar__content {
        padding: 2rem 0;

        .container {
          justify-content: flex-start;

          .label,
          input {
            height: @el-height-tablet;
          }

          .input-wrapper {
            flex: 1 1 auto;
            max-width: 40rem;
            font-size: 1.4rem;

            .magnifying-glass {
              top: 1.3rem;
              right: 1.5rem;
            }
          }
        }
      }
    }

    @media only screen and (min-width: @breakpoint-medium) {
      .filter-bar__content {
        padding: 2rem 0;

        .container {
          .label,
          input {
            font-size: 1.8rem;
          }

          .label {
            display: flex;
            align-items: center;
          }

          .input-wrapper {
            margin-left: auto;
            flex: 1 1 25.6rem;
            max-width: 25.6rem;
            font-size: 1.8rem;

            input {
              padding: 0 4rem 0 2rem;
            }
          }
        }
      }
    }
  }
}

.avalon-area {

  .quick-games.search-games-component {
    @el-height-mobile: 4rem;
    @el-height-tablet: 4.8rem;

    .container--filterbar {
      max-width: 64.7rem;
    }

    .filter-bar__search {
      display: flex;
      width: 100%;
      gap: .8rem;
    }

    .filter-bar__toggle {
      display: block;
      width: 5.2rem;
      height: 5.2rem;
      cursor: pointer;

      .filter {
        fill:@color-light-eggplant;
        transition: fill .3s;
      }

      &:hover,
      &.open {
        .filter {
          fill:@color-dark-eggplant;
        }
      }
    }

    .filter-bar__content {
      padding: 1.6rem 0;

      .container {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .label,
        input {
          height: @el-height-mobile;
        }

        .label {
          display: none;
          color: @color-white;
          font-size: 1.4rem;
          padding: 0 2rem 0 0.1rem;
        }

        .input-wrapper {
          flex: 1 0 80%;
          position: relative;
          margin-bottom: 3.2rem;
          margin-left: auto;

          input {
            -webkit-appearance: none;
            box-sizing: border-box;
            //width: ~"calc(100% - 6rem)";
            width: 100%;
            height: 5.2rem;
            border-radius: 3rem;
            box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.5);
            font-size: 1.6rem;
            color: #7f7f7f;
            border: none;
            padding: 0 3.5rem 0 4.5rem;

            &::placeholder {
              color: #000;
            }
          }

          .magnifying-glass {
            position: absolute;
            top: 1.7rem;
            left: 1.6rem;
            height: 2rem;
          }
        }

        .filter-bar__filters {
          max-height: 0;
          margin-bottom: 0;
          overflow: hidden;
          transition: all .3s ease-in;

          &.open {
            max-height: 20rem;
            margin-bottom: 1.2rem;
            overflow: visible;
            transition: all .2s ease-in;
            width: 100%;

            .search-tags,
            .dropdowns-container {
              opacity: 1;
            }
          }
        }

        .search-tags {
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          gap: 1rem;
          margin-bottom: 2.7rem;
          opacity: 0;
          transition: opacity .4s;

          &__tag {
            display: inline-block;
            border: .1rem solid @color-white;
            padding: .5rem 2rem;
            border-radius: 2.2rem;
            color: @color-white;
            letter-spacing: 1px;
            text-transform: uppercase;
            font-size: 1.4rem;
            font-weight: 700;
            transition: all .2s;

            &:hover {
              cursor: pointer;
              background-color: @color-dark-eggplant;
            }

            &.selected {
              background-color: @color-dark-eggplant;
              border-color: @color-dark-eggplant;
            }
          }

        }
      }
    }

    @media only screen and (min-width: @breakpoint-small) {
      .filter-bar__toggle {
        display: none;
      }

      .filter-bar__content {
        padding: 2rem 0;

        .container {
          justify-content: flex-start;

          .label,
          input {
            height: @el-height-tablet;
          }

          .filter-bar__filters {
            max-height: 20rem;
            overflow: visible;
            margin-bottom: 3.2rem;
          }

          .search-tags {
            opacity: 1;
            margin-bottom: 3.2rem;
          }

          .input-wrapper {
            flex: 1 1 auto;
            width: 100%;
            font-size: 1.4rem;
          }
        }
      }
    }
  }
}
