@import '../Variables/breakpoints.less';
@import '../Variables/colors.less';

.avalon-area .quick-games {
  .game-section-games {
    margin-top: 5rem;
    position: relative;

    .title {
      color: @color-white;
      font-size: 2.4rem;
      font-family: "Helium";
      font-weight: 400;
      text-transform: none;

      @media(min-width: @breakpoint-small) {
        font-size: 4rem;
      }
    }

    .text {
      color: @color-white;
    }

    .tns-outer {
      position: relative;

      .tns-nav {
        position: absolute;
        top: -5rem;
        right: 0;
        display: none;

        @media only screen and (min-width: @breakpoint-small) {
          display: block;
        }

        button {
          display: inline-block;
          width: .6rem;
          height: .6rem;
          background: @color-white;
          border: none;
          margin-left: .6rem;
          padding: 0;
          border-radius: .3rem;
        }
        .tns-nav-active {
          width: 2.8rem;
          background-color: @color-yellow-orange;
        }
      }
      .tns-inner {
        margin: 0;
      }
    }
  }

  .game-section-games__slider {
    padding-top: 1.4rem;
    display: flex;
  }

  .game-section-games__slider-not-initialized {
    overflow: hidden;
    .card {
      margin-right: 5rem;
    }
    .card.card--small {
      margin-right: 1rem;
    }
  }

  .game-section-games__controls {
    display: none;
    @media only screen and (min-width: @breakpoint-small) {
      display: block;
      cursor: pointer;
      width: 1.2rem;
      height: 1.2rem;
      position: absolute;
      bottom: 50%;
      right: -1.5rem;

      &--prev {
        left: -1rem;
        transform: rotate(-180deg);
      }

      &:focus,
      &:hover {
        text-decoration: none;
        outline: none;
      }

      &:before {
        content: '';
        display: block;
        border: .2rem solid @color-white;
        border-left: none;
        border-bottom: none;
        width: 100%;
        height: 100%;
        transform: rotate(45deg);
      }
    }

    @media // this targets tablets
      only screen and (min-width: @breakpoint-small) and (-webkit-min-device-pixel-ratio: 2),
      only screen and (min-width: @breakpoint-small) and (-moz-device-pixel-ratio: 2) {
      display: none;
    }

    @media only screen and (min-width: @breakpoint-medium) {
      right: -5rem;

      &--prev {
        left: -5rem;
      }
    }
  }
}
