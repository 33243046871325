@import '../Variables/breakpoints.less';
@import '../Variables/colors.less';

.avalon-area .quick-games {
  &.search-games-component {
    .no-games {
      min-height: 23.9rem;
      margin-top: 4.7rem;
      color: @color-white;
      padding: 2rem;
      max-width: 500px;
      font-size: 2rem;
      font-weight: 300;
      line-height: 1.5;
      text-align: center;

      .quick-button {
        width: 24rem;
        margin: 4rem auto;
      }
    }

    .search-games-result {
      background: @color-light-eggplant;
      border-radius: 4rem;
      margin: 0 0 1.5rem;
      padding: 2.5rem 0;

      @media only screen and (min-width: @breakpoint-small) {
        margin: 1.3rem 0 2rem;
        padding: 4rem 0;
      }
    }

    .separator {
      height: 0.2rem;
      background-color: @color-grape;
      width: 100%;
      margin: 0 0 1rem;

      @media only screen and (min-width: @breakpoint-small) {
        margin: 2rem 0 1rem;
      }
    }

    .search-games-component-title {
      margin-bottom: 3.8rem;
      text-align: center;
      color: @color-white;
      font-size: 4.4rem;

      @media only screen and (min-width: @breakpoint-small) {
        margin-top: 6rem;
        font-size: 7.6rem;
      }
    }

    .search-result-heading {
      color: @color-white;
      font-size: 2.4rem;
      font-family: "Helium";
      text-transform: none;

      @media only screen and (min-width: @breakpoint-small) {
        margin-top: 1rem;
        font-size: 4rem;
      }

      span {
        display: none;
      }

      .search-phrase {
        display: inline;
      }

      .active {
        display: inline;
      }
    }

    .cards__container {
      display: flex;
      flex-wrap: wrap;
      gap: 5%;
      width: 100%;
      @media only screen and (min-width: @breakpoint-small) {
        gap: 2rem;
      }

      .card {
        @media only screen and (max-width: @breakpoint-small) {
          .card__link {
            width: 100%;
          }
        }
      }
    }

    .cards__container--center {
      justify-content: center;
    }
  }
}