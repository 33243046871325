@import '../Variables/breakpoints.less';
@import '../Variables/colors.less';

// Background image
.region-avalon-quick {
  background-repeat: no-repeat;
  background-position-y: 9rem !important;
  background-size: 60rem auto;

  @media only screen and (min-width: @breakpoint-small) {
    background-position-y: 15rem !important;
    background-size: 120rem auto;
  }
  
  @media only screen and (min-width: @breakpoint-medium) {
    background-size: 150rem auto;
  }
}