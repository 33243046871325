﻿@import "../../../../../../../DanskeSpil/Framework/Design/Styles/Mixins/DanskeSpilMixins.less"; 
@import url("colors.less");

.text-shadow(@x: 1px, @y: 1px, @blur: 1px, @color: @quick-dark-purple) {
  text-shadow: @x @y @blur @color;
}

.quick-sprite(@left:0, @top:0) {
  background-image: url(~'@{design-base-url}/Graphics/regions/quick/sprite-quick.png');
  background-repeat: no-repeat;
  background-position:@left @top;
}
