﻿@import (reference) "../../AvalonComponents/Styles/Variables/colors";

body.LEIA-spilside {
  // Our Navigation sets a top padding, which is overwritten with a style attribute by https://leia.danskespil.dk/static/bridge/falcon-game-bridge-helper.min.js.
  // We therefore need to re-overwrite this (hence the use of !important).
  padding: unset !important;
  padding-top: calc(~"var(--navigation-compliance-bar-height, 0rem) + var(--navigation-main-menu-height, 0rem) + var(--navigation-sub-menu-height, 0rem)") !important;
}

#leia-game-wrapper {
  position: relative;
  clear: both;
  display: flex;
  align-items: center;
  flex-direction: column;

  body.is-mobile-detected & {
    .container-background-image {
      display: none;
    }
  }

  body.is-desktop-detected & {
    background-color: @ds-purple;
    padding: 20px 0;
    overflow: hidden;
    border-radius: 0rem 0rem 4rem 4rem;

    &.transparent-background {
      background-color: transparent;

      .container-background-image {
        position: absolute;
        inset: -0.8rem;
        height: auto;
        width: auto;
        padding: 4rem 0;
        background-size: cover;
        background-position: center;
        filter: blur(4px);
        border-radius: 0rem 0rem 4rem 4rem;
      }
    }
  }
}

.leia-game-outer-wrapper {
  body.is-desktop-detected & {
    display: flex;
    flex-direction: column;
  }
}

.leia-game-outer-wrapper__image {
  display: none;

  body.is-desktop-detected & {
    display: flex;
    justify-content: center;

    img {
      width: 200px;
    }
  }
}

#leia-game-inner-wrapper {
  position: relative;
  -webkit-overflow-scrolling: touch;
  overflow: hidden;

  body.is-desktop-detected & {
    width: 1030px;
    max-width: 1030px;
    box-shadow: 0 1.5rem 3.5rem 0.2rem rgba(0,0,0,.5);
    border-radius: .3rem;
    background-color: rgba(204,180,207,.25);

    &.loading {
      transition: width 0.5s;
    }
  }
  // IOS FULLSCREEN OVERLAY
  #leia-ios-swipe-up-overlay {
    display: none;
  }

  &.visible-swipe-up-overlay #leia-ios-swipe-up-overlay {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 10000;
    background-color: rgb(114, 37, 109);
    overflow: hidden;

    p {
      width: 80%;
      font-size: 2.6rem;
      font-weight: bold;
      text-align: center;
      color: #fff;
      margin: auto;
      position: absolute;
      top: 50%;
      left: 0;
      right: 0;
      transform: translateY(-50%);
      will-change: transform;

      @media all and (orientation:landscape) {
        width: 40%;
      }
    }

    @keyframes swipe-up-background-arrows-leia-animation {
      0% {
        transform: rotate(-90deg) translateX(0);
      }

      100% {
        transform: rotate(-90deg) translateX(100vh);
      }
    }

    @leia-arrow-background-color1: #7f4488;
    @leia-arrow-background-color2: #72256D;
    @leia-arrow-background-width: 60px;

    .swipe-up-underlay {
      position: absolute;
      width: 50%;
      height: 60%;
      overflow: hidden;
      left: 50%;
      top: 50%;
      transform: translateX(-50%) translateY(-50%);
      border-radius: 20vw;
      border: 4px solid #7f4488;
      filter: drop-shadow(0 10px 10px rgb(82, 27, 79));

      @media all and (orientation:landscape) {
        width: 20vw;
        height: 80vh;
        border-radius: 9vw;
      }

      &:before {
        content: '';
        width: 300vh;
        height: 100vw;
        position: absolute;
        left: -26vw;
        bottom: -200vh;
        background-image: repeating-linear-gradient(45deg, @leia-arrow-background-color1 0px, @leia-arrow-background-color1 @leia-arrow-background-width, @leia-arrow-background-color2 @leia-arrow-background-width, @leia-arrow-background-color2 calc(@leia-arrow-background-width * 2)), repeating-linear-gradient(135deg, @leia-arrow-background-color1 0px, @leia-arrow-background-color1 @leia-arrow-background-width, @leia-arrow-background-color2 @leia-arrow-background-width, @leia-arrow-background-color2 calc(@leia-arrow-background-width * 2));
        background-size: 100% 50.1%;
        background-position: top left, bottom left;
        background-repeat: no-repeat;
        transform: rotate(-90deg);
        transform-origin: 0 0;
        will-change: transform;
        animation: swipe-up-background-arrows-leia-animation 20s linear infinite;

        @media all and (orientation:landscape) {
          left: -40.7vw;
          bottom: -200vw;
        }
      }
    }
  }
  // ANDROID FULLSCREEN OVERLAY
  #leia-android-fullscreen-overlay {
    display: none;
  }

  &.visible-android-fullscreen-overlay #leia-android-fullscreen-overlay {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 10000;
    background-color: rgb(114, 37, 109);

    p {
      color: #fff;
      margin-top: 33vh;
      text-align: center;
    }
  }
  // MESSAGE (LOADING OR ERROR)
  #leia-message {
    text-align: center;
    color: #fff;
    margin: 40px;
    display: none;
  }

  &.loading, &.error {
    #leia-game {
      display: none;
    }

    #leia-message {
      display: block;
    }
  }
  // If fullscreen is displayed, remove other messages
  &.visible-swipe-up-overlay #leia-message, &.visible-android-fullscreen-overlay #leia-message {
    display: none !important;
  }
}
// Spacer for IOS Swipe up overlay
#leia-game-ios-swipe-up-spacer {
  display: none;
  height: 100px;
}


@media all and (orientation:landscape) {
  :root:has(body.hide-top-navigation-on-mobile-landscape.is-mobile-detected) {
    --navigation-main-menu-height: 0rem;
    --navigation-sub-menu-height: 0rem;
  }

  body.hide-top-navigation-on-mobile-landscape.is-mobile-detected .ds-navigation {
    display: none;
  }
}
