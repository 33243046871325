body.LEIA-spilside {
  padding: unset !important;
  padding-top: calc(var(--navigation-compliance-bar-height, 0rem) + var(--navigation-main-menu-height, 0rem) + var(--navigation-sub-menu-height, 0rem)) !important;
}
#leia-game-wrapper {
  position: relative;
  clear: both;
  display: flex;
  align-items: center;
  flex-direction: column;
}
body.is-mobile-detected #leia-game-wrapper .container-background-image {
  display: none;
}
body.is-desktop-detected #leia-game-wrapper {
  background-color: #7f4488;
  padding: 20px 0;
  overflow: hidden;
  border-radius: 0rem 0rem 4rem 4rem;
}
body.is-desktop-detected #leia-game-wrapper.transparent-background {
  background-color: transparent;
}
body.is-desktop-detected #leia-game-wrapper.transparent-background .container-background-image {
  position: absolute;
  inset: -0.8rem;
  height: auto;
  width: auto;
  padding: 4rem 0;
  background-size: cover;
  background-position: center;
  filter: blur(4px);
  border-radius: 0rem 0rem 4rem 4rem;
}
body.is-desktop-detected .leia-game-outer-wrapper {
  display: flex;
  flex-direction: column;
}
.leia-game-outer-wrapper__image {
  display: none;
}
body.is-desktop-detected .leia-game-outer-wrapper__image {
  display: flex;
  justify-content: center;
}
body.is-desktop-detected .leia-game-outer-wrapper__image img {
  width: 200px;
}
#leia-game-inner-wrapper {
  position: relative;
  -webkit-overflow-scrolling: touch;
  overflow: hidden;
}
body.is-desktop-detected #leia-game-inner-wrapper {
  width: 1030px;
  max-width: 1030px;
  box-shadow: 0 1.5rem 3.5rem 0.2rem rgba(0, 0, 0, 0.5);
  border-radius: .3rem;
  background-color: rgba(204, 180, 207, 0.25);
}
body.is-desktop-detected #leia-game-inner-wrapper.loading {
  transition: width 0.5s;
}
#leia-game-inner-wrapper #leia-ios-swipe-up-overlay {
  display: none;
}
#leia-game-inner-wrapper.visible-swipe-up-overlay #leia-ios-swipe-up-overlay {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 10000;
  background-color: #72256d;
  overflow: hidden;
}
#leia-game-inner-wrapper.visible-swipe-up-overlay #leia-ios-swipe-up-overlay p {
  width: 80%;
  font-size: 2.6rem;
  font-weight: bold;
  text-align: center;
  color: #fff;
  margin: auto;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
  will-change: transform;
}
@media all and (orientation: landscape) {
  #leia-game-inner-wrapper.visible-swipe-up-overlay #leia-ios-swipe-up-overlay p {
    width: 40%;
  }
}
@keyframes swipe-up-background-arrows-leia-animation {
  0% {
    transform: rotate(-90deg) translateX(0);
  }
  100% {
    transform: rotate(-90deg) translateX(100vh);
  }
}
#leia-game-inner-wrapper.visible-swipe-up-overlay #leia-ios-swipe-up-overlay .swipe-up-underlay {
  position: absolute;
  width: 50%;
  height: 60%;
  overflow: hidden;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  border-radius: 20vw;
  border: 4px solid #7f4488;
  filter: drop-shadow(0 10px 10px #521b4f);
}
@media all and (orientation: landscape) {
  #leia-game-inner-wrapper.visible-swipe-up-overlay #leia-ios-swipe-up-overlay .swipe-up-underlay {
    width: 20vw;
    height: 80vh;
    border-radius: 9vw;
  }
}
#leia-game-inner-wrapper.visible-swipe-up-overlay #leia-ios-swipe-up-overlay .swipe-up-underlay:before {
  content: '';
  width: 300vh;
  height: 100vw;
  position: absolute;
  left: -26vw;
  bottom: -200vh;
  background-image: repeating-linear-gradient(45deg, #7f4488 0px, #7f4488 60px, #72256D 60px, #72256D calc(120px)), repeating-linear-gradient(135deg, #7f4488 0px, #7f4488 60px, #72256D 60px, #72256D calc(120px));
  background-size: 100% 50.1%;
  background-position: top left, bottom left;
  background-repeat: no-repeat;
  transform: rotate(-90deg);
  transform-origin: 0 0;
  will-change: transform;
  animation: swipe-up-background-arrows-leia-animation 20s linear infinite;
}
@media all and (orientation: landscape) {
  #leia-game-inner-wrapper.visible-swipe-up-overlay #leia-ios-swipe-up-overlay .swipe-up-underlay:before {
    left: -40.7vw;
    bottom: -200vw;
  }
}
#leia-game-inner-wrapper #leia-android-fullscreen-overlay {
  display: none;
}
#leia-game-inner-wrapper.visible-android-fullscreen-overlay #leia-android-fullscreen-overlay {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 10000;
  background-color: #72256d;
}
#leia-game-inner-wrapper.visible-android-fullscreen-overlay #leia-android-fullscreen-overlay p {
  color: #fff;
  margin-top: 33vh;
  text-align: center;
}
#leia-game-inner-wrapper #leia-message {
  text-align: center;
  color: #fff;
  margin: 40px;
  display: none;
}
#leia-game-inner-wrapper.loading #leia-game,
#leia-game-inner-wrapper.error #leia-game {
  display: none;
}
#leia-game-inner-wrapper.loading #leia-message,
#leia-game-inner-wrapper.error #leia-message {
  display: block;
}
#leia-game-inner-wrapper.visible-swipe-up-overlay #leia-message,
#leia-game-inner-wrapper.visible-android-fullscreen-overlay #leia-message {
  display: none !important;
}
#leia-game-ios-swipe-up-spacer {
  display: none;
  height: 100px;
}
@media all and (orientation: landscape) {
  :root:has(body.hide-top-navigation-on-mobile-landscape.is-mobile-detected) {
    --navigation-main-menu-height: 0rem;
    --navigation-sub-menu-height: 0rem;
  }
  body.hide-top-navigation-on-mobile-landscape.is-mobile-detected .ds-navigation {
    display: none;
  }
}
.leia-predeposit-overlay {
  width: 100vw;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  animation: all-or-nothing-overlay-background-animation 0.3s forwards linear;
}
@keyframes all-or-nothing-overlay-background-animation {
  0% {
    background-color: rgba(0, 0, 0, 0);
  }
  100% {
    background-color: rgba(0, 0, 0, 0.3);
  }
}
@keyframes all-or-nothing-overlay-panel-animation {
  0% {
    transform: translateY(-50%) scale(0.85);
    filter: blur(0.3rem);
    opacity: 0;
  }
  100% {
    transform: translateY(-52%) scale(1);
    filter: blur(0);
    opacity: 1;
  }
}
@keyframes all-or-nothing-overlay-panel-remove-animation {
  0% {
    transform: translateY(-52%) scale(1);
    filter: blur(0);
    opacity: 1;
  }
  100% {
    transform: translateY(-50%) scale(0.85);
    filter: blur(0.3rem);
    opacity: 0;
  }
}
.leia-predeposit-overlay .pannel {
  width: 80%;
  background-color: #7f4488;
  color: #ffffff;
  margin: auto;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-52%);
  padding: 5.3333vw;
  box-sizing: border-box;
  border-radius: 6px;
  animation: all-or-nothing-overlay-panel-animation 0.3s forwards linear;
}
@media screen and (min-width: 1024px) and (max-width: 1408px), screen and (min-width: 1408px), screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait), screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .leia-predeposit-overlay .pannel {
    width: 46rem;
    padding: 3rem;
  }
}
.leia-predeposit-overlay .close-overlay-button-wrapper {
  width: 7vw;
  height: 7vw;
  cursor: pointer;
  position: absolute;
  top: -3.5vw;
  right: -3.5vw;
  background-color: #fff;
  border-radius: 3.5vw;
  padding: 2vw;
  box-shadow: 2px 2px 10px rgba(119, 119, 119, 0);
}
@media screen and (min-width: 1024px) and (max-width: 1408px), screen and (min-width: 1408px), screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait), screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .leia-predeposit-overlay .close-overlay-button-wrapper {
    width: 5rem;
    height: 5rem;
    top: -2.5rem;
    right: -2.5rem;
    padding: 1.2rem;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1408px), screen and (min-width: 1408px), screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait), screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .leia-predeposit-overlay .close-overlay-button-wrapper:hover .button-icon {
    transform: scale(1.1);
  }
}
.leia-predeposit-overlay .close-overlay-button-wrapper .button-icon {
  width: 100%;
  height: 100%;
  fill: #7f4488;
  transition: transform .15s linear;
  filter: drop-shadow(1px 2px 2px rgba(0, 0, 0, 0.5));
}
.leia-predeposit-overlay .headline {
  font-size: 6vw;
  font-weight: bold;
  margin: 0 0 3.5vw 0;
}
@media screen and (min-width: 1024px) and (max-width: 1408px), screen and (min-width: 1408px), screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait), screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .leia-predeposit-overlay .headline {
    font-size: 2.8rem;
    margin: 1.2rem 1.5rem 1rem;
  }
}
.leia-predeposit-overlay .text {
  font-size: 3vw;
  margin: 3vw 0 0;
}
@media screen and (min-width: 1024px) and (max-width: 1408px), screen and (min-width: 1408px), screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait), screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .leia-predeposit-overlay .text {
    font-size: 2.1rem;
    margin: 0.3rem 1.5rem 2.2rem;
  }
}
.leia-predeposit-overlay .buttons {
  margin: 4.5vw 0 0;
}
.leia-predeposit-overlay .buttons .btn {
  box-sizing: border-box;
  outline: none;
  border: none;
  border-radius: 1vw;
  cursor: pointer;
  font-weight: bold;
  font-size: 3.8vw;
  line-height: 10.7vw;
  padding: 0 4vw;
  display: inline-block;
  position: relative;
  vertical-align: top;
  text-decoration: none;
  background-color: #feb700;
  min-width: 32vw;
  transition: background-color 0.15s linear;
  overflow: hidden;
}
@media screen and (min-width: 1024px) and (max-width: 1408px), screen and (min-width: 1408px), screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait), screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .leia-predeposit-overlay .buttons .btn {
    min-width: 16rem;
    font-size: 2rem;
    line-height: 4rem;
    border-radius: 5px;
    padding: 0 1.4rem;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1408px), screen and (min-width: 1408px), screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait), screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .leia-predeposit-overlay .buttons {
    margin: 1.5rem 0 0;
  }
  .leia-predeposit-overlay .buttons .btn {
    width: 12.4rem;
  }
  .leia-predeposit-overlay .buttons > .btn:first-child {
    float: none;
    margin-right: .5rem;
  }
  .leia-predeposit-overlay .buttons > .btn:last-child {
    float: none;
    margin-left: .5rem;
  }
}
.leia-predeposit-overlay.remove-overlay {
  animation: all-or-nothing-overlay-background-animation 0.15s reverse linear;
}
.leia-predeposit-overlay.remove-overlay .pannel {
  animation: all-or-nothing-overlay-panel-remove-animation 0.15s forwards linear;
}
