@import '../Variables/breakpoints.less';
@import '../Variables/colors.less';

.avalon-area {
  @scratchctawidth: 15.2rem;
  @scratchctaheight: 5.4rem;

  .card .scratch-cta__wrapper,
  .mega-card .scratch-cta__wrapper {
    display: none;

    @media only screen and (min-width: @breakpoint-medium) {
      position: relative;
      display: flex;
      height: 8rem;
      width: 100%;
      top: 1rem;
    }
  }

  .scratch-cta__line {
    user-select: none;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    height: @scratchctaheight;
    width: @scratchctawidth;

    &--1,
    &--2,
    &--3 {
      position: absolute;
      height: @scratchctaheight;
      width: @scratchctawidth;
      clip-path: inset(0 100% 0 0);
    }

    &--2 {
      clip-path: inset(0 0 0 100%);
    }
  }

  .scratch-cta__svg {
    position: absolute;
    z-index: 99;
    top: 0;
    left: 0;

    &--1 {
      left: 1rem;
    }

    &--2 {
      top: 0.3rem;
    }

    &--3 {
      left: 2.5rem;
      top: 1.6rem;
    }
  }

  .scratch-cta__text {
    position: absolute;
    color: black;
    top: 0;
    left: 0;
    text-align: center;
    font-size: 1.5rem;
    z-index: 100;
    font-weight: 800;
    width: @scratchctawidth;
    height: @scratchctaheight;
    line-height: 1;
    padding-top: 1.9rem;

    &--1 {
      clip-path: polygon(0% 0, 100% 0%, 100% 35%, 0% 53%);
    }

    &--2 {
      clip-path: polygon(0% 40%, 100% 0%, 100% 35%, 0% 90%);
    }
  }

  .card:hover,
  .mega-card:hover {
    @media only screen and (min-width: @breakpoint-medium) {
      .scratch-cta__wrapper {
        .scratch-cta__line {
          &--1 {
            animation: reveal-left-to-right 100ms;
            animation-delay: 150ms;
            animation-fill-mode: forwards;
          }
          &--2 {
            animation: reveal-right-to-left 100ms;
            animation-delay: 250ms;
            animation-fill-mode: forwards;
          }
          &--3 {
            animation: reveal-left-to-right 100ms;
            animation-delay: 350ms;
            animation-fill-mode: forwards;
          }
        }
      }
    }
  }

  @keyframes reveal-left-to-right {
    from {
      clip-path: inset(0 100% 0 0);
    }
    to {
      clip-path: inset(0 0 0 0);
    }
  }

  @keyframes reveal-right-to-left {
    from {
      clip-path: inset(0 0 0 100%);
    }
    to {
      clip-path: inset(0 0 0 0);
    }
  }

}
