.top-navigation-theme-quick .menu-toggle-right {
  background-color: #7f4488;
}
.top-navigation-theme-quick .brand-nav {
  background-color: #7f4488;
}
.top-navigation-theme-quick .corporate-nav {
  background-color: #672d70;
}
.top-navigation-theme-quick .mobile-navigation-header {
  background-color: #7f4488;
}
.top-navigation-theme-quick .mobile-navigation-footer-icon {
  background-color: #7f4488;
}
.top-navigation-theme-quick .mobile-navigation-item-link:before {
  background-color: #7f4488;
}
.top-navigation-theme-quick .corporate-nav__navigation-header,
.top-navigation-theme-quick .brand-nav__navigation-header {
  background-color: #7f4488;
}
.top-navigation-theme-quick .corporate-nav__navigation-footer-icon,
.top-navigation-theme-quick .brand-nav__navigation-footer-icon {
  background-color: #7f4488;
}
.top-navigation-theme-quick .corporate-nav__navigation .corporate-nav__list-link:hover,
.top-navigation-theme-quick .brand-nav__navigation .corporate-nav__list-link:hover {
  color: #fff;
}
.top-navigation-theme-quick .corporate-nav__navigation .brand-nav__link:before,
.top-navigation-theme-quick .brand-nav__navigation .brand-nav__link:before {
  background-color: #7f4488;
}
.top-navigation-theme-quick .mega-menu__campaign__cta-button {
  background-color: #7f4488;
  color: white;
}
.top-navigation-theme-quick .mega-menu__campaign__cta-button:hover {
  opacity: .9;
}
