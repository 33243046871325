@import '../Variables/breakpoints.less';
@import '../Variables/colors.less';

.quick-button {
  align-self: flex-end;
  border-radius: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 5rem;
  min-width: 18rem;
  padding: 0 2.5rem;
  background: @color-yellow-orange;
  font-size: 1.4rem;
  text-transform: uppercase;
  font-weight: 800;
  color: @color-text;
  box-shadow: 0 0.2rem 0.2rem 0 rgba(0,0,0,.25);

  &:hover {
    background: #eba825;
  }

  &--small-font {
    font-size: 1.2rem;
  }
}

