@import '../Variables/breakpoints.less';
@import '../Variables/colors.less';

//OLD CARD: TODO: TO BE DELETED AFTER QUICK DIGITAL RELEASE
.avalon-area {
  .quick-games.game-list-spot-component, .quick-games.featured-games-spot, .quick-games.featured-gamelist-spot {
    .card {
      display: flex;
      position: relative;
      height: 239px;
      background-color: @color-card-bg;
      margin: 0 0.6rem 1rem;
      flex: 0 0 14.6rem;
      width: 14.6rem;
      border-radius: 0.5rem;
      outline: 0;

      @media only screen and (min-width: @breakpoint-small) {
        width: 17rem;
        flex-basis: 17rem;
        height: 22.8rem;
        margin: 1rem 0.8rem 1.7rem;
      }

      @media only screen and (min-width: @breakpoint-medium) {
        width: 25.6rem;
        flex-basis: 25.6rem;
        height: 24rem;
        margin: 1rem 2.5rem 1.5rem;
      }

      &.hidden {
        display: none;
      }

      &:before,
      &:after {
        z-index: -1;
        position: absolute;
        content: '';
        bottom: 1.5rem;
        left: 0.3rem;
        width: 50%;
        top: 80%;
        max-width: 14.6rem;
        background: rgba(0, 0, 0, 0.4);
        box-shadow: 0 1.7rem 1rem rgba(0, 0, 0, 0.4);
        transform: rotate(-4deg);
      }

      &:after {
        transform: rotate(4deg);
        right: 0.3rem;
        left: auto;
      }

      &:hover {

        .card__content {
          transform: translateY(0);
        }

        .card-image__background {
          @media only screen and (min-width: @breakpoint-medium) {
            transform: scale(1.02);
          }
        }

        .card-image__overlay {
          @media only screen and (min-width: @breakpoint-medium) {
            transform: scale(0.95) translateY(-8px);
          }
        }
      }
    }

    .card__wrapper {
      position: relative;
      overflow: hidden;
      height: 100%;
      width: 100%;
      border-radius: 4px;
    }

    .card__content {
      @media only screen and (min-width: @breakpoint-medium) {
        transform: translateY(7.5rem);
        transition: transform 200ms ease-out;
      }
    }

    .card__title {
      font-size: 1.4rem;
      font-weight: 600;
      line-height: 1.4;
      text-transform: none;
      margin-bottom: 0;

      @media only screen and (min-width: @breakpoint-medium) {
        font-size: 1.7rem;
      }
    }

    .card__text {
      font-size: 1.2rem;
      margin-top: 0;
      margin-bottom: 1rem;

      @media only screen and (min-width: @breakpoint-small) {
        font-size: 1.4rem;
      }
    }

    .card__label {
      margin: 0 0 0.6rem;
      color: black;
      background-color: @color-white;
      font-size: 1.1rem;
      display: table;
      padding: 0.3rem 1.3rem 0.3rem 0.8rem;
      border-radius: 0.3rem 1rem 1rem 0.3rem;

      @media only screen and (min-width: @breakpoint-medium) {
        display: inline-block;
        font-size: 1.4rem;
      }

      span {
        font-weight: 800;
      }

      &--price {
        margin-bottom: 0.2rem;
        background-color: @color-yellow-orange;
        border-radius: 0.3rem;

        @media only screen and (min-width: @breakpoint-medium) {
          padding: 0.3rem 0.8rem;
          margin: 0 0.2rem 0.2rem 0;
        }
      }

      &--prize {
        @media only screen and (min-width: @breakpoint-medium) {
          padding: 0.3rem 1.3rem 0.3rem 0.8rem;
          border-radius: 0.3rem 1.5rem 1.5rem 0.3rem;
        }
      }
    }

    .card-image__background {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      border-radius: 4px;
      z-index: 0;
      transition: transform 200ms ease-out;

      // Media query to insure that IE10+ applies a height to img
      @media all and (-ms-high-contrast: none) and (max-width: @breakpoint-medium),
        (-ms-high-contrast: active) {
        width: auto;
        height: 100%;
      }
    }

    .card-image__overlay {
      position: relative;
      border-radius: 4px;
      z-index: 1;
      transition: transform 200ms ease-out;

      @media only screen and (max-width: @breakpoint-medium) {
        width: 100%;
      }
    }

    .card__images {
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .card__content {
      z-index: 1;
      background-color: rgba(0, 0, 0, 0.5);
      width: 100%;
      padding: 0.8rem 0.8rem 0;
      position: absolute;
      left: 0;
      bottom: 0;
      color: @color-white;
    }

    .card__attribute {
      position: absolute;
      z-index: 10;
    }

    .card__attribute--new {
      top: -1.2rem;
      right: 0.5rem;
      height: 4rem;

      @media only screen and (min-width: @breakpoint-medium) {
        top: -1.5rem;
        right: 0.8rem;
        height: 7rem;
      }
    }

    .card__attribute--popular {
      top: -0.3rem;
      left: -0.3rem;
      width: 6.4rem;
      height: 6.4rem;

      @media only screen and (min-width: @breakpoint-medium) {
        width: 8rem;
        height: 8rem;
      }
    }
  }
}

@card-border-radius: 3rem; //Fallback

@card-micro-width: 9.5rem;
@card-micro-height: 11rem;
@card-micro-container-height: 21rem;
@card-micro-border-radius: 1.8rem;
@card-micro-card-gap: 2rem;

@card-small-width: 14.5rem;
@card-small-height: 17rem;
@card-small-container-height: 27rem;
@card-small-border-radius: 2rem;
@card-small-card-gap: 2rem;

@card-medium-width: 15.5rem;
@card-medium-height: 19.8rem;
@card-medium-container-height: 30rem;
@card-medium-border-radius: 2.3rem;
@card-medium-card-gap: 3rem;

@card-large-width: 19.5rem;
@card-large-height: 25rem;
@card-large-container-height: 36rem;
@card-large-border-radius: 3rem;
@card-large-card-gap: 5rem;

.avalon-area .quick-games {

  .hero-deck__slider, .game-section-games, .games-section, &.search-games-component {
    .card {
      position: relative;
      min-height: 36rem; //Fallback

      &--micro {
        height: @card-micro-container-height;

        &.tns-item {
          display: flex;
          justify-content: center;
        }

        .card__wrapper {
          background-color: @color-brand-quick;
          width: @card-micro-width;
        }

        .card-image__background, .card-image__overlay {
          width: 100%;
        }

        .card-image__overlay {
          top: inherit;
          bottom: 0;
        }

        .card__link {
          height: @card-micro-height;
          border-radius: @card-micro-border-radius;
          transition: all .1s linear;
          .card__label {
            display: none;
          }
        }

        .ribbon-top-left span {
          left: -3.25rem;
          top: 0.75rem;
          transform: rotate(-40deg);
          height: 1.25rem;
          font-size: 7px;
          padding: 1px 0;
        }

        &:not(.has-events)[aria-hidden="true"] {
          .card__images {
            display: none;
          }
        }
      }

      &--small {
        height: @card-small-container-height;
        min-height: @card-small-container-height;

        &.tns-item {
          padding: 0 5px!important;
          @media only screen and (min-width: @breakpoint-small) {
            padding: 0 10px!important;
          }
        }

        &.tns-slide-active {
          .card__link {
            box-shadow: 0 0 2rem rgba(0, 0, 0, 0.3);
          }
        }

        .card__wrapper {
          width: @card-small-width;
        }

        .card-image__background, .card-image__overlay {
          width: 100%;
        }

        .card__meta {
          font-size: 1.2rem;
          margin-top: 1.4rem;

          @media only screen and (min-width: @breakpoint-small) {
            font-size: 1.1rem;
          }
        }

        .card__meta-2 {
          font-size: 1.5rem;
        }

        .card__meta-3 {
          font-size: 1.2rem;
        }

        .card__link {
          height: @card-small-height;
          border-radius: @card-small-border-radius;
          box-shadow: none;
          .card__label {
            height: 3rem;
            font-size: 1rem;
            margin-bottom: .8rem;
          }
        }

        .card__counter {
          bottom: 7rem;
          font-size: 4rem;
        }
      }

      &--medium {
        height: @card-medium-container-height;
        min-height: @card-small-container-height;

        &.tns-item {
          padding: 0 10px!important;
        }

        &.tns-slide-active {
          .card__link {
            box-shadow: 0 0 2rem rgba(0, 0, 0, 0.3);
          }
        }

        .card__wrapper {
          width: @card-medium-width;
        }

        .card-image__background, .card-image__overlay {
          width: 100%;
        }

        .card__meta {
          font-size: 1.25rem;
          margin-top: 1.4rem;

        }

        .card__meta-2 {
          font-size: 1.5rem;
        }

        .card__meta-3 {
          font-size: 1.2rem;
        }

        .card__link {
          height: @card-medium-height;
          border-radius: @card-medium-border-radius;
          box-shadow: none;
          .card__label {
            height: 3rem;
            font-size: 1.2rem;
            margin-bottom: .8rem;
          }
        }

        .card__counter {
          bottom: 6.75rem;
          font-size: 5rem;
        }
      }

      &--large {
        min-height: @card-large-container-height;

        &.tns-item {
          padding: 0 25px!important;
        }

        &.tns-slide-active {
          .card__link {
            box-shadow: 0 0 2rem rgba(0, 0, 0, 0.3);
          }
        }

        .card__wrapper {
          width: @card-large-width;
        }

        .card-image__background, .card-image__overlay {
          width: 100%;
        }

        .card__link {
          height: @card-large-height;
          box-shadow: none;
        }
      }

      &--no-meta {
        .card__meta,
        .card__counter {
          display: none;
        }

        &.card--micro {
          height: @card-micro-height;
        }
        &.card--small {
          height: @card-small-height;
        }
        &.card--small {
          height: @card-medium-height;
        }
      }

      &:first-child {
        .card__counter {
          left: -.1rem;
        }
      }

      &.hidden {
        display: none;
      }
    }

    .card__link {
      display: flex;
      position: relative;
      height: 25rem; //Fallback
      box-shadow: 0 0 2rem rgba(0, 0, 0, 0.3);
      outline: 0;
      border-radius: @card-border-radius;
      overflow: hidden;
      z-index: 10;
    }

    .card__labels {
      width: 80%;
    }

    .card__label {
      color: @color-text;
      background-color: @color-white;
      font-size: 1.2rem;
      display: flex;
      padding: 0.3rem 1rem 0.3rem 1rem;
      border-radius: 2rem;
      justify-content: center;
      flex-wrap: nowrap;
      flex-direction: row;
      align-items: center;

      @media only screen and (min-width: @breakpoint-medium) {
        font-size: 1.4rem;
      }

      span {
        font-weight: 800;
      }

      &--price {
        margin-bottom: 1.2rem;
        height: 4rem;
        background-color: @color-yellow-orange;
        width: 100%;
        text-align: center;

        .price {
          text-transform: uppercase;
        }
      }

      &--prize {
        @media only screen and (min-width: @breakpoint-medium) {
          padding: 0.3rem 1.3rem 0.3rem 0.8rem;
          border-radius: 0.3rem 1.5rem 1.5rem 0.3rem;
        }
      }
    }

    .card__counter {
      position: absolute;
      z-index: 10;
      bottom: 7.9rem;
      left: 0;
      opacity: .7;
      font-size: 7.2rem;
      font-weight: 800;
      color: transparent;
      overflow: hidden;
      -webkit-text-stroke: 1px @color-white;
      -webkit-background-clip: text;
      background-color: rgba(127, 68, 136, .6);
    }

    .card-image__background {
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      z-index: 0;
      transition: transform 200ms ease-out;
      object-fit: cover;
      width: 100%;
    }

    .card-image__overlay {
      position: absolute;
      left: 0;
      top: 0;
      z-index: 1;
      transition: transform 200ms ease-out;
      object-fit: cover;
      width: 100%;
      height: inherit;
    }

    .card__content {
      z-index: 1;
      width: 100%;
      position: absolute;
      left: 0;
      bottom: 0;
      color: @color-white;
      display: flex;
      justify-content: center;
      flex-direction: row;
    }

    .card__meta {
      padding-left: .9rem;
      margin-top: 1.8rem;

      .card--no-meta &{
        display: none;
      }
    }

    .card__meta-1 {
      display: block;
      color: @color-white;
      font-size: inherit;
      font-weight: 400;
    }

    .card__meta-2 {
      display: block;
      color: @color-white;
      font-size: 1.8rem;
      font-weight: 700;
    }

    .card__meta-3 {
      display: block;
      color: @color-white;
      font-size: 1.6rem;
      font-weight: 600;
    }

    .ribbon {
      width: 100%;
      height: 100%;
      overflow: hidden;
      position: absolute;
      z-index: 10;
      left: 0;
    }

    .ribbon span {
      position: absolute;
      display: flex;
      justify-content: center;
      width: 10rem;
      height: 2rem;
      padding: 2px 0;
      background-color: @color-placeholder;
      box-shadow: 0 5px 10px rgba(0,0,0,.1);
      color: @color-white;
      font-size: 1rem;
      text-shadow: 0 1px 1px rgba(0,0,0,.2);
      text-transform: uppercase;
      text-align: center;
    }

    .ribbon-top-left span {
      left: -2.75rem;
      top: 0.75rem;
      transform: rotate(-40deg);
    }
  }
}
