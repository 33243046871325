@import '../Variables/breakpoints.less';
@import '../Variables/colors.less';

.quick-hero {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2rem;
  margin-bottom: 1rem;

  @media only screen and (min-width: @breakpoint-small) {
    margin-top: 3rem;
    flex-direction: row;
    justify-content: space-between;
  }

  .quick-hero__image-wrapper {
    @media only screen and (min-width: @breakpoint-small) {
      flex: 0 0 50%;
      text-align: center;
    }
    
    .quick-hero__image {
      max-width: 100%;
    }
  }

  .quick-hero__text-content {
    width: 100%;
    line-height: 1.4;
    
    @media only screen and (min-width: @breakpoint-small) {
      flex: 0 0 50%;
      padding: 0 2rem;
    }
  }

  .quick-hero__headline {
    color: @color-white;
    font-size: 2.6rem;
    line-height: 1.3;
    margin-bottom: 1.5rem;
    font-weight: 700;
    text-transform: none;

    @media only screen and (min-width: @breakpoint-small) {
      font-size: 3.2rem;
    }
  }

  .quick-hero__description {
    color: @color-white;
    font-size: 1.8rem;
    margin-bottom: 2.5rem;

    @media only screen and (min-width: @breakpoint-small) {
      font-size: 2.1rem;
    }
  }

  .hero__labels {
    margin: 0 0 0.8rem;
    color: @color-text;
  }

  .hero__labels--price,
  .hero__labels--prize {
    display: inline-block;
    font-size: 1.4rem;
    background: @color-white;

    @media only screen and (min-width: @breakpoint-medium) {
      font-size: 1.6rem;
    }

    span {
      font-weight: 800;
    }
  }

  .hero__labels--price {
    background-color: @color-yellow-orange;
    padding: 0.5rem 1rem;
    border-radius: 0.3rem;
    margin-right: 0.8rem;
    font-weight: 800;
  }

  .hero__labels--prize {
    padding: 0.5rem 1.75rem 0.5rem 1rem;
    border-radius: 0.3rem 1.5rem 1.5rem 0.3rem;
  }


  .quick-hero__cta {
    padding: 1.3rem 3rem;
  }
}
