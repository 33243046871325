.overlay-region-avalon-quick,
.overlay-region-quick {
  margin: 100px auto 60px auto;
  width: 980px;
  min-height: 200px;
  background: #3e2948 url('/Components/DanskeSpil/Project/Design/Graphics/regions/quick/overlay-background-quick.jpg') 0 0 no-repeat;
  border-radius: 1px;
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding;
  background-clip: padding-box;
  -moz-box-shadow: 0px 10px 15px 2px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 0px 10px 15px 2px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 10px 15px 2px rgba(0, 0, 0, 0.75);
  position: relative;
  left: -10px;
}
.overlay-region-avalon-quick .header,
.overlay-region-quick .header {
  height: 71px;
  position: relative;
}
.overlay-region-avalon-quick .header .title,
.overlay-region-quick .header .title {
  margin: 0;
  position: absolute;
  top: 25px;
  left: 31px;
  color: #fff;
  font-size: 2.5rem;
  line-height: 28px;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 800;
  font-style: normal;
}
.overlay-region-avalon-quick .header .close,
.overlay-region-quick .header .close {
  position: absolute;
  top: 13px;
  right: 30px;
  width: 42px;
  height: 43px;
  background-color: #7f4488;
  border: 1px solid #43154b;
  border-radius: 1px;
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding;
  background-clip: padding-box;
  -moz-box-shadow: 0px 2px 3px 1px rgba(0, 0, 0, 0.25);
  -webkit-box-shadow: 0px 2px 3px 1px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 2px 3px 1px rgba(0, 0, 0, 0.25);
  cursor: pointer;
}
.overlay-region-avalon-quick .header .close:hover,
.overlay-region-quick .header .close:hover {
  text-decoration: none;
}
.overlay-region-avalon-quick .header .close:before,
.overlay-region-quick .header .close:before {
  content: "";
  display: block;
  height: 1px;
  background: #9e71a5;
  margin-right: 1px;
  margin-left: 1px;
}
.overlay-region-avalon-quick .header .close:after,
.overlay-region-quick .header .close:after {
  content: "";
  position: absolute;
  top: 12px;
  left: 12px;
  width: 18px;
  height: 19px;
  background-image: url(/Components/DanskeSpil/Project/Design/Graphics/regions/all-regions/sprite-global.png);
  background-repeat: no-repeat;
  background-position: -300px -100px;
}
.overlay-region-avalon-quick .column-wrapper,
.overlay-region-quick .column-wrapper {
  width: 100%;
  padding: 0 30px;
}
.overlay-region-avalon-quick .column-wrapper:before,
.overlay-region-quick .column-wrapper:before,
.overlay-region-avalon-quick .column-wrapper:after,
.overlay-region-quick .column-wrapper:after {
  content: "";
  display: table;
}
.overlay-region-avalon-quick .column-wrapper:after,
.overlay-region-quick .column-wrapper:after {
  clear: both;
}
.overlay-region-avalon-quick .column-wrapper .primary-column,
.overlay-region-quick .column-wrapper .primary-column {
  float: right;
  width: 72.17391304%;
}
.overlay-region-avalon-quick .column-wrapper .primary-column.full-size,
.overlay-region-quick .column-wrapper .primary-column.full-size {
  width: 100%;
}
.overlay-region-avalon-quick .column-wrapper .primary-column .article,
.overlay-region-quick .column-wrapper .primary-column .article {
  background: #fff;
  border-radius: 3px;
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding;
  background-clip: padding-box;
  -moz-box-shadow: 0 15px 35px 2px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 15px 35px 2px rgba(0, 0, 0, 0.5);
  box-shadow: 0 15px 35px 2px rgba(0, 0, 0, 0.5);
  min-height: 300px;
  padding: 23px 30px 60px 32px;
}
.overlay-region-avalon-quick .column-wrapper .primary-column .article:before,
.overlay-region-quick .column-wrapper .primary-column .article:before,
.overlay-region-avalon-quick .column-wrapper .primary-column .article:after,
.overlay-region-quick .column-wrapper .primary-column .article:after {
  content: "";
  display: table;
}
.overlay-region-avalon-quick .column-wrapper .primary-column .article:after,
.overlay-region-quick .column-wrapper .primary-column .article:after {
  clear: both;
}
.overlay-region-avalon-quick .column-wrapper .primary-column .article .headline,
.overlay-region-quick .column-wrapper .primary-column .article .headline {
  text-shadow: none;
  color: #333333;
  font-size: 3rem;
  line-height: 33px;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 800;
  font-style: normal;
  margin-bottom: 12px;
}
.overlay-region-avalon-quick .column-wrapper .primary-column .article .image,
.overlay-region-quick .column-wrapper .primary-column .article .image {
  float: right;
  margin-left: 40px;
  margin-bottom: 20px;
}
.overlay-region-avalon-quick .column-wrapper .primary-column .article .html-text,
.overlay-region-quick .column-wrapper .primary-column .article .html-text {
  color: #333333;
  font-size: 1.5rem;
  line-height: 18px;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: normal;
  font-style: normal;
}
.overlay-region-avalon-quick .column-wrapper .primary-column .article .html-text p,
.overlay-region-quick .column-wrapper .primary-column .article .html-text p {
  line-height: inherit;
  margin-bottom: 20px;
}
.overlay-region-avalon-quick .column-wrapper .primary-column .article .html-text p:last-child,
.overlay-region-quick .column-wrapper .primary-column .article .html-text p:last-child {
  margin: 5px;
}
.overlay-region-avalon-quick .column-wrapper .aside-column,
.overlay-region-quick .column-wrapper .aside-column {
  float: left;
  width: 23.47826087%;
}
.overlay-region-avalon-quick .column-wrapper .aside-navigation,
.overlay-region-quick .column-wrapper .aside-navigation {
  background-color: #7f4488;
  border-radius: 3px;
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding;
  background-clip: padding-box;
  overflow: hidden;
  width: 100%;
  margin-bottom: 15px;
}
.overlay-region-avalon-quick .column-wrapper .aside-navigation .item,
.overlay-region-quick .column-wrapper .aside-navigation .item {
  list-style: none;
  margin: 0;
  display: block;
}
.overlay-region-avalon-quick .column-wrapper .aside-navigation .item a,
.overlay-region-quick .column-wrapper .aside-navigation .item a {
  display: block;
  color: #fff;
  font-size: 1.5rem;
  line-height: 18px;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 800;
  font-style: normal;
  padding: 16px 30px 16px 20px;
  border-bottom: 1px solid #66306e;
  position: relative;
}
.overlay-region-avalon-quick .column-wrapper .aside-navigation .item a:after,
.overlay-region-quick .column-wrapper .aside-navigation .item a:after {
  content: "";
  position: absolute;
  right: 10px;
  top: 14px;
  width: 12px;
  height: 21px;
  background-image: url(/Components/DanskeSpil/Project/Design/Graphics/regions/all-regions/sprite-global.png);
  background-repeat: no-repeat;
  background-position: -300px -200px;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=40)";
  opacity: 0.4;
}
.overlay-region-avalon-quick .column-wrapper .aside-navigation .item a:hover,
.overlay-region-quick .column-wrapper .aside-navigation .item a:hover {
  text-decoration: none;
  background-color: #43154b;
}
.overlay-region-avalon-quick .column-wrapper .aside-navigation .item a:hover:after,
.overlay-region-quick .column-wrapper .aside-navigation .item a:hover:after {
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  opacity: 1;
}
.overlay-region-avalon-quick .column-wrapper .aside-navigation .item a.selected,
.overlay-region-quick .column-wrapper .aside-navigation .item a.selected {
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 700;
  font-style: normal;
  background-color: #43154b;
}
.overlay-region-avalon-quick .column-wrapper .aside-navigation .item a.selected:after,
.overlay-region-quick .column-wrapper .aside-navigation .item a.selected:after {
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  opacity: 1;
}
.overlay-region-avalon-quick .column-wrapper .aside-navigation .item:last-child a,
.overlay-region-quick .column-wrapper .aside-navigation .item:last-child a {
  border-bottom: none;
}
.overlay-region-avalon-quick .footer,
.overlay-region-quick .footer {
  position: relative;
  height: 75px;
  width: 100%;
}
.overlay-region-avalon-quick .footer:before,
.overlay-region-quick .footer:before {
  content: "";
  background-image: url(/Components/DanskeSpil/Project/Design/Graphics/regions/all-regions/sprite-global.png);
  background-repeat: no-repeat;
  background-position: -100px -100px;
  position: absolute;
  top: 29px;
  left: 30px;
  width: 145px;
  height: 23px;
}
.overlay-region-avalon-quick .footer:after,
.overlay-region-quick .footer:after {
  content: "";
  background-image: url(/Components/DanskeSpil/Project/Design/Graphics/regions/quick/sprite-quick.png);
  background-repeat: no-repeat;
  background-position: -400px -400px;
  position: absolute;
  top: 30px;
  right: 25px;
  width: 72px;
  height: 21px;
}
.overlay-region-avalon-quick .footer strong,
.overlay-region-quick .footer strong {
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  overflow: hidden;
  position: absolute;
  clip: rect(0 0 0 0);
}
