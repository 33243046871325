@import '../Variables/colors.less';

// spin 1
.spin-1 {
  animation-name: spin-1;
  animation-duration: 10s;
  animation-iteration-count: infinite;
  animation-timing-function: cubic-bezier(.5,1,1,1);

  @keyframes spin-1 {
    /* 0% { transform:  } */
    50% { transform: rotate(360deg); }
    /*100% { transform: ; }*/
  }
}

// bounce
.bounce {
  animation-name: bounce;
  animation-duration: .5s;
  animation-iteration-count: infinite;
  animation-timing-function: cubic-bezier(.5,1,1,1);

  @keyframes bounce {
    0% { transform:  translate(0, -30%); }
    50% { transform: translate(0px, 0px); }
    51% { transform: translate(0px, -10%); }
    100% { transform: scale(.99) translate(0, -30%); }
  }
}

.float {
  animation-name: float;
  animation-duration: 4s;
  animation-iteration-count: infinite;

  @keyframes float {
    0% { transform:  translate(0, 50%);  }
    20% { transform: translate(0, -50%); }
    30% { transform: translate(0, -20%); }
    50% { transform: translate(0, -50%); }
    60% { transform: translate(0, -30%); }
    80% { transform: translate(0, -60%); }
    100% { transform: translate(0, -40%); }
  }

}

// boomerang
.boomerang {
  animation-name: boomerang;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;

  @keyframes boomerang {
    0% { transform: scale(0.08) translate(0, 0); }
    5% { transform: scale(0.1) translate(50%, 10%); }
    25% { transform: scale(0.25) translate(200%, -50%); }
    50% { transform: scale(1.4) translate(0, 30%); }
    75% {  transform: scale(0.25) translate(-200%, -50%); }
    95% { transform: scale(0.05) translate(-50%, 5%); }
    100% { transform: scale(0.05) translate(0, 0); }
  }
}

// spin 2
.spin-2 {
  animation-name: spin2;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;

  @keyframes spin2 {
    0% { transform: rotate(0deg); }
    50% { transform: rotate(180deg); }
    100% { transform: rotate(360deg); }
  }
}

// rotate
.rotate {
  animation-name: rotate;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;

  @keyframes rotate {
    0% { transform: rotateX(0deg); }
    50% { transform: rotateX(180deg); }
    100% { transform: rotateX(360deg); }
  }
}

// blink with pause
.blink-with-pause {
  animation-name: blink-with-pause;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;

  @keyframes blink-with-pause {
    0% { opacity:0; }
    15% { opacity:1; }
    30% { opacity:0; }
    42% { opacity:1; }
    60% { opacity:0; }
    /* Pause starts here, increase
        the pause duration by changing
        the values as needed */
    65% { opacity:1; }
    100% { opacity:1; }
  }
}

// glowing on hover
.glowing-on-hover {
  &:before {
    content: '';
    // Rainbow
    // background: linear-gradient(45deg, #ff0000, #ff7300, #fffb00, #48ff00, #00ffd5, #002bff, #7a00ff, #ff00c8, #ff0000);
    //Quick like colors
    background: linear-gradient(45deg, #5ffbf9, #feb700, #fffb00, #82bf08, #00ffd5, #9cb560, #b298c8, @color-light-eggplant, @color-yellow-orange);
    position: absolute;
    top: -4px;
    left:-2px;
    background-size: 100%;
    z-index: -1;
    filter: blur(5px);
    width: calc(100% + 1.3rem);
    height: calc(100%);
    animation: glowing 20s linear infinite;
    opacity: 0;
    transition: opacity .3s ease-in-out;
    border-radius: 4.5rem;
  }

  &:hover:before {
    opacity: 1;
  }

  @keyframes glowing {
    0% { background-position: 0 0; }
    50% { background-position: 400% 0; }
    100% { background-position: 0 0; }
  }
}

.circle-around {
  animation: circle-around 12s infinite linear;

  img {
    animation: rotateImage 12s infinite linear;
  }

  &:nth-of-type(2) {
    animation: circle-around 15s infinite linear;

    img {
      animation: rotateImage 15s infinite linear;
    }
  }

  &:nth-of-type(3) {
    animation: circle-around 18s infinite linear;

    img {
      animation: rotateImage 18s infinite linear;
    }
  }

  &:nth-of-type(4) {
    animation: circle-around 21s infinite linear;

    img {
      animation: rotateImage 21s infinite linear;
    }
  }

  &:nth-of-type(5) {
    animation: circle-around 24s infinite linear;

    img {
      animation: rotateImage 24s infinite linear;
    }
  }

  @keyframes circle-around {
    from {
      transform: rotate(0deg) translate(15px);
    }
    to {
      transform: rotate(360deg) translate(15px);
    }
  }

  @keyframes rotateImage {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(-360deg);
    }
  }
}
