﻿// bring in mixins

// Media querie:
@media-querie-for-mobile-portrait: ~"screen and (min-width: 320px) and (max-width: 480px)";
@media-querie-for-mobile-landscape: ~"screen and (min-width: 481px) and (max-width: 767px)";
@media-querie-for-tablet-landscape: ~"screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape)";
@media-querie-for-tablet-portrait: ~"screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait)";
@media-querie-for-desktop: ~"screen and (min-width: 1024px) and (max-width: 1408px)";
@media-querie-for-large-desktop: ~"screen and (min-width: 1408px)";



@quick-overlay-background-color: rgb(127, 68, 136);
@quick-overlay-text-color: rgb(255, 255, 255);

@quick-overlay-cta-yellow: #feb700;
@quick-overlay-cta-yellow-hover: #f5d900;


@quick-overlay-animation-duration: .3s;

.leia-predeposit-overlay {
  @keyframes all-or-nothing-overlay-background-animation {
    0% {
      background-color: rgba(0,0,0,0);
    }

    100% {
      background-color: rgba(0,0,0,.3);
    }
  }

  width: 100vw;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  animation: all-or-nothing-overlay-background-animation @quick-overlay-animation-duration forwards linear;

  @keyframes all-or-nothing-overlay-panel-animation {
    0% {
      transform: translateY(-50%) scale(.85);
      filter: blur(.3rem);
      opacity: 0;
    }

    100% {
      transform: translateY(-52%) scale(1);
      filter: blur(0);
      opacity: 1;
    }
  }

  @keyframes all-or-nothing-overlay-panel-remove-animation {
    0% {
      transform: translateY(-52%) scale(1);
      filter: blur(0);
      opacity: 1;
    }

    100% {
      transform: translateY(-50%) scale(.85);
      filter: blur(.3rem);
      opacity: 0;
    }
  }

  .pannel {
    width: 80%;
    background-color: @quick-overlay-background-color;
    color: @quick-overlay-text-color;
    margin: auto;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    transform: translateY(-52%);
    padding: 5.3333vw;
    box-sizing: border-box;
    border-radius: 6px;
    animation: all-or-nothing-overlay-panel-animation @quick-overlay-animation-duration forwards linear;

    
    @media @media-querie-for-desktop, @media-querie-for-large-desktop, @media-querie-for-tablet-portrait, @media-querie-for-tablet-landscape {
      width: 46rem;
      padding: 3rem;
    }
    
  }

  .close-overlay-button-wrapper {
    width: 7vw;
    height: 7vw;
    cursor: pointer;
    position: absolute;
    top: -3.5vw;
    right: -3.5vw;
    background-color: #fff;
    border-radius: 3.5vw;
    padding: 2vw;
    box-shadow: 2px 2px 10px rgba(119, 119, 119, 0);

    @media @media-querie-for-desktop, @media-querie-for-large-desktop, @media-querie-for-tablet-portrait, @media-querie-for-tablet-landscape {
      width: 5rem;
      height: 5rem;
      top: -2.5rem;
      right: -2.5rem;
      padding: 1.2rem;
    }
    &:hover {
      .button-icon {
        @media @media-querie-for-desktop, @media-querie-for-large-desktop, @media-querie-for-tablet-portrait, @media-querie-for-tablet-landscape {
          transform: scale(1.1);
        }
      }
    }

    .button-icon {
      width: 100%;
      height: 100%;
      fill: @quick-overlay-background-color;
      transition: transform .15s linear;
      filter: drop-shadow( 1px 2px 2px rgba(0, 0, 0, 0.5));
    }
  }

  .headline {
    font-size: 6vw;
    font-weight: bold;
    margin: 0 0 3.5vw 0;

    @media @media-querie-for-desktop, @media-querie-for-large-desktop, @media-querie-for-tablet-portrait, @media-querie-for-tablet-landscape {
      font-size: 2.8rem;
      margin: 1.2rem 1.5rem 1rem;
    }
  }

  .text {
    font-size: 3vw;
    margin: 3vw 0 0;

    @media @media-querie-for-desktop, @media-querie-for-large-desktop, @media-querie-for-tablet-portrait, @media-querie-for-tablet-landscape {
      font-size: 2.1rem;
      margin: 0.3rem 1.5rem 2.2rem;
    }
  }

  .buttons {
    margin: 4.5vw 0 0;
    //.clearfix();
    .btn {
      box-sizing: border-box;
      outline: none;
      border: none;
      border-radius: 1vw;
      cursor: pointer;
      font-weight: bold;
      font-size: 3.8vw;
      line-height: 10.7vw;
      padding: 0 4vw;
      display: inline-block;
      position: relative;
      vertical-align: top;
      text-decoration: none;
      background-color: @quick-overlay-cta-yellow;
      min-width: 32vw;
      transition: background-color 0.15s linear;
      overflow: hidden;

      @media @media-querie-for-desktop, @media-querie-for-large-desktop, @media-querie-for-tablet-portrait, @media-querie-for-tablet-landscape {
        min-width: 16rem;
        font-size: 2rem;
        line-height: 4rem;
        border-radius: 5px;
        padding: 0 1.4rem;
      }

    }

    @media @media-querie-for-desktop, @media-querie-for-large-desktop, @media-querie-for-tablet-portrait, @media-querie-for-tablet-landscape {
      margin: 1.5rem 0 0;
      .btn {
        width: 12.4rem;
      }
      & > .btn:first-child {
        float: none;
        margin-right: .5rem;
      }
      & > .btn:last-child {
        float: none;
        margin-left: .5rem;
      }
    }
  }

  &.remove-overlay {
    animation: all-or-nothing-overlay-background-animation @quick-overlay-animation-duration/2 reverse linear;

    .pannel {
      animation: all-or-nothing-overlay-panel-remove-animation @quick-overlay-animation-duration/2 forwards linear;
    }
  }
}
