@import '../Variables/breakpoints.less';
@import '../Variables/colors.less';
@import "../../../AvalonComponents/Styles/_imports.less";

.avalon-area .quick-games {
  .winner-stories {
    margin: 9rem 0 4rem;
    padding-bottom: 0.1rem;

    .winner-stories__wrapper {
      display: flex;
      justify-content: space-between;
    }
  }

  .winner-stories__title {
    color: @color-white;
    font-size: 4rem;
    text-align: center;
    text-transform: none;
  }

  .winner-stories__text {
    color: @color-white;
    text-align: center;
    max-width: 50rem;
    margin: 0 auto;
  }

  .winner-stories__story {
    width: 23.2rem;
    max-width: 100%;
    padding-right: 20px;
  }

  .winner-stories__story-image {
    width: auto;
    height: auto;
    border-radius: 50%;
    overflow: hidden;
    margin: 4rem 0;

    @media only screen and (min-width: @breakpoint-small) {
      width: 19.2rem;
      height: 19.2rem;
    }

    img {
      width: 100%;
      height: 100%;
    }
  }

  .winner-stories__story-content {
    min-height: 43rem;
    padding: 0 2rem;
  }

  .winner-stories__story-title {
    color: @color-white;
    font-size: 1.6rem;
    margin-bottom: 1.2rem;
    text-transform: none;
  }

  .winner-stories__story-text {
    color: @color-white;
    font-size: 1.4rem;
  }

  .winner-stories__slider {
    display: flex;
  }

  .winner-stories__slider-wrapper {
    position: relative;
    padding: 0 10%;
    @media only screen and (min-width: @lg-breakpoint) {
      padding: 0 10rem 3rem;
    }

    @media only screen and (min-width: @breakpoint-medium) {
      padding: 0 15rem 5rem;
    }
  }

  .winner-stories__controls {
    display: block;
    cursor: pointer;
    width: 1.6rem;
    height: 1.6rem;
    position: absolute;
    top: 50%;
    right: 0;

    &--prev {
      left: 0;
    }
  }

  .tns-nav {
    text-align: center;
    margin: 3rem 0;

    @media only screen and (min-width: @breakpoint-small) {
      display: block;
    }

    button {
      display: inline-block;
      width: .6rem;
      height: .6rem;
      background: @color-white;
      border: none;
      margin-left: .6rem;
      padding: 0;
      border-radius: .3rem;
    }
    .tns-nav-active {
      background-color: @color-yellow-orange;
    }
  }
}