@import '../Variables/breakpoints.less';
@import '../Variables/colors.less';

.region-avalon-quick {
  .hero-deck {
    display: block;
  }

  .hero-deck__background {
    background-size: cover;
    background-position: center;
    opacity: .9;
    overflow: hidden;

    @media only screen and (min-width: @breakpoint-small) {
      padding-bottom: 5rem;
    }
  }

  .hero-deck__hero-container {
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media only screen and (min-width: @breakpoint-small) {
      align-items: normal;
    }

    .quick-button {

      &--animate {
        animation: bounces .5s infinite alternate;
      }
    }

    @keyframes bounces {
      0% {
        transform: translateY(-5px);
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
      }
    }
  }

  .featured-games__heading-container {
    display: none;
    @media only screen and (min-width: @breakpoint-small) {
      display: block;
    }
  }

  .hero-deck__hero {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    position: relative;

    @media only screen and (min-width: @breakpoint-small) {
      height: 100%;
      top: 2rem;
    }

    .additional-images--position-1 {
      left: 37%;
      top: 10%;

      @media only screen and (min-width: @breakpoint-small) {
        top: 11%;
      }

      @media only screen and (min-width: @breakpoint-medium) {
        top: 8%;
      }
    }

    .additional-images--position-2 {
      top: 37%;

      @media only screen and (min-width: @breakpoint-small) {
        left: 5%;
      }

      @media only screen and (min-width: @breakpoint-medium) {
        left: 16%;
      }
    }

    .additional-images--position-3 {
      left: 2%;
      top: 84%;

      @media only screen and (min-width: @breakpoint-small) {
        left: 18%;
        top: 71%
      }

      @media only screen and (min-width: @breakpoint-medium) {
        left: 30%;
      }
    }

    .additional-images--position-4 {
      left: 90%;
      top: 10%;

      @media only screen and (min-width: @breakpoint-small) {
        left: 85%;
      }

      @media only screen and (min-width: @breakpoint-medium) {
        left: 81%;
      }
    }

    .additional-images--position-5 {
      left: 82%;
      top: 53%;

      @media only screen and (min-width: @breakpoint-small) {
        left: 77%;
      }

      @media only screen and (min-width: @breakpoint-medium) {
        left: 73%;
      }
    }

    .additional-images {
      display: none;
      @media(min-width: @breakpoint-small) {
        display: flex;
        height: 4rem;
        width: 4rem;
        position: relative;

        img {
          width: inherit;
        }
      }

      &.hidden {
        display: none;
      }
    }
  }

  .hero-deck__hero-additional-images {
    position: absolute;
    width: 100%;
    display: inline-block;
    height: 100%;
  }

  .hero-deck__hero-image {
    width: 85%;
    min-height: 33vh;
    object-fit: cover;

    @media only screen and (min-width: @breakpoint-small) {
      height: 500px;
      width: 50rem;
    }
  }

  .hero-deck__container {
    padding: 0;
    margin: 2rem auto 0 auto;
    position:relative;
  }

  .hero-deck__slider-container {
    border-radius: 3rem;
    position: relative;

    .tns-outer {
      margin-top: -80px;
    }

    &:before {
      content: '';
      display: block;
      height: 20px;
    }


    &:after {
      content: '';
      background: rgba(255,255,255,0.6);
      border-radius: 3rem;
      position: absolute;
      top: 2.7rem;
      height: 13.4rem;
      width: 100%;
      z-index: -1;
    }
  }

  .hero-deck__slider {
    padding: 10rem 0 1.2rem;
    display: flex;
    height: 24rem;

    .card {
      transition: all .2s linear;

      &.active {
        .card__link {
          transition: all .1s linear;
          border: 3px solid @color-white;
          transform: scale(1.33) translateY(5px);
          margin: 0 1.2rem;
          transform-origin: bottom;
        }
      }

      @media only screen and (min-width: @breakpoint-small) {
        &.card__before-previous {
          transform: scale(1.1) translateY(7px);
        }

        &.card__previous {
          transform: scale(1.25) translateY(15px);
        }

        &.card__hover {
          transform: scale(1.5) translateY(25px);
          margin: 0 2rem;
          z-index: 10;
        }

        &.card__next {
          transform: scale(1.25) translateY(15px);
        }

        &.card__after-next {
          transform: scale(1.1) translateY(7px);
        }

        &.active {
          .card__link {
            transition: all .2s linear;
            border: 3px solid @color-white;
            transform: scale(1);
            margin: revert;
          }
        }
      }
    }
  }

  .hero-deck__controls {
    display: none;

    @media only screen and (min-width: @breakpoint-small) {
      display: block;
      cursor: pointer;
      width: 3.5rem;
      height: 3.5rem;
      position: absolute;
      top: 44%;
      right: -3rem;
      filter: drop-shadow(2px 4px 2px fade(@ds-black, 40%));

      &--prev {
        left: -3rem;
      }

      &:focus,
      &:hover {
        text-decoration: none;
        outline: none;
      }
    }

    @media only screen and (min-width: @breakpoint-medium) {
      right: -5rem;

      &--prev {
        left: -5rem;
      }
    }
  }

  .hero-deck__slider-container--slider-not-initialized {
    .hero-deck__slider {
      overflow: hidden;
      padding: 2rem 0 1.2rem;
      height: 17rem;

      .card {
        margin: 0 26px;
        display: none;
      }
    }
    .hero-deck__controls {
      top: 41%;
    }
    .tns-outer {
      margin-top: 0;
    }
  }

  .hero-deck__hero-texts-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: baseline ;
    position: relative;
    z-index: 10;

    .heading {
      margin-top: auto;
      display: none;
      @media only screen and (min-width: @breakpoint-small) {
        display: block;
      }

      h1 {
        margin-bottom: 0;
        font-size: 4rem;
        font-family: "Helium";
        text-shadow: .1rem .1rem @color-placeholder;
      }
    }
  }

  .hero-deck__hero-texts {
    display: none;

    @media only screen and (min-width: @breakpoint-small) {
      margin-top: auto;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      color: @color-white;
      right: 0;
      white-space: nowrap;
      width: 20rem;
    }

    &--mobile {
      display: block;
      text-align: center;
      padding: 0 0 6rem;

      @media only screen and (min-width: @breakpoint-small) {
        display: none;
      }
    }

    .hero-deck__hero-prize-text {
      margin: .2rem 0;
    }
    .hero-deck__hero-title {
      margin-bottom: .2rem;
      text-transform: none;
    }

    .hero-deck__hero-type-text {
      margin-top: .2rem;
      margin-bottom: 0;
    }

    .hero-deck__hero-prize-text,
    .hero-deck__hero-title,
    .hero-deck__hero-type-text {
      overflow: hidden;
      height: 2.5rem;
      color: @color-white;
      text-shadow: .1rem .1rem @color-placeholder;
      &.animating {
        animation: scaleDown ease .6s;
      }
    }
    .hero-deck__hero-title {
      height: 3rem;
    }
  }
}

@keyframes scaleDown {
  0% {transform: scaleY(0);}
  100% {transform: scaleY(-4rem);}
}
