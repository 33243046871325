@import '../Variables/breakpoints.less';
@import '../Variables/colors.less';

.avalon-area .quick-games {
  &.game-list-spot-component {
    .no-games {
      min-height: 23.9rem;
      margin-top: 4.7rem;
      color: @color-white;
      padding: 2rem;
      max-width: 500px;
      font-size: 2rem;
      font-weight: 300;
      line-height: 1.5;
      text-align: center;
    }

    .scroll-to-top {
      position: relative;
      display: block;
      width: 4.8rem;
      height: 4.8rem;
      border-radius: .4rem;
      background-color: @color-grape;
      margin: 5rem auto;
      border: 0.1rem solid transparent;

      &:after {
        content: ' ';
        position: absolute;
        right: 1.5rem;
        top: 1.9rem;
        border: solid @color-yellow-orange;
        border-width: 0 0.2rem 0.2rem 0;
        display: inline-block;
        padding: 0.65rem;
        transform: rotate(225deg);
        margin-bottom: 0.4rem;
      }

      &:hover {
        border: 0.1rem solid @color-yellow-orange;
      }
    }

    .separator {
      height: 0.2rem;
      background-color: @color-grape;
      width: 100%;
      margin: 0 0 1rem;

      @media only screen and (min-width: @breakpoint-small) {
        margin: 2rem 0 1rem;
      }
    }

    .cards {
      margin: 0;
      padding: 1rem 1rem 0;

      @media only screen and (min-width: @breakpoint-small) {
        margin: 0 auto;
        width: calc(@breakpoint-small ~" - 2.4rem");
        padding: 0;
      }

      @media only screen and (min-width: @breakpoint-medium) {
        width: @breakpoint-medium;
      }
    }

    .cards__container {
      @media only screen and (min-width: @breakpoint-small) {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
      }
    }

    .cards__container--center {
      justify-content: center;
    }

    // swiper-active is a class set by FeaturedGames.js to indicate that we have js powered swiper active
    .swiper-active .cards__container--center {
      justify-content: flex-start;
    }

    .game-spot__heading {
      min-height: 4.6rem;
      padding: 0.4rem 0.1rem;
      margin: 0;

      h1.title {
        margin: 1rem 0 0 0;
        font-size: 2rem;
        color: @color-white;
        font-weight: 600;
        display: inline-block;
        text-transform: none;
      }

      h2.subtitle {
        display: inline-block;
        font-weight: 300;
        font-size: 1.6rem;
        color: @color-white;
        margin: 0 0 0 1rem;
        text-transform: none;
      }
    }

    @media only screen and (min-width: @breakpoint-small) {
      .game-spot__heading {
        padding: 0.4rem 0.1rem 1.3rem;

        h1.title {
          font-size: 2.4rem;
        }

        h2.subtitle {
          font-size: 2rem;
        }
      }
    }
  }
}
