﻿@import url("../variables.less");

.top-navigation-theme-quick {
  .menu-toggle-right {
    background-color: #7f4488;
  }

  .brand-nav {
    background-color: #7f4488;
  }

  .corporate-nav {
    background-color: #672d70;
  }

  .mobile-navigation {
    &-header {
      background-color: #7f4488;
    }
    &-footer {
      &-icon {
        background-color: #7f4488;
      }
    }
    &-item {
      &-link {
        &:before {
          background-color: #7f4488;
        }
      }
    }
  }

  .corporate-nav__navigation,
  .brand-nav__navigation {
    &-header {
      background-color: #7f4488;
    }

    &-footer {
      &-icon {
        background-color: #7f4488;
      }
    }

    .corporate-nav__list-link {
      &:hover {
        color: #fff;
      }
    }

    .brand-nav__link {
      &:before {
        background-color: #7f4488;
      }
    }
  }

  .mega-menu {
    &__campaign {
      &__cta-button {
        background-color: #7f4488;
        color: white;

        &:hover {
          opacity: .9;
        }
      }
    }
  }
}
