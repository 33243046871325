@import '../Variables/breakpoints.less';
@import '../Variables/colors.less';

.avalon-area .gameview {
  .gameview__info {
    display: flex;
    background: @color-grape;
    padding: 1.6rem 0;
    color: @color-white;

    .container {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      align-items: flex-start;

      @media only screen and (min-width: @breakpoint-small) {
        flex-direction: row;
        align-items: center;
      }

      .gameview__demo-text {
        padding: 1rem 0;
      }
    }
    // Hide on mobile and tablet
    body.is-mobile-detected &, body.is-tablet-detected & {
      display: none;
    }
  }

  .gameview__button--cta,
  .gameview__button--secondary {
    padding: 1.3rem 3rem;
  }

  .gameview__button--secondary {
    color: @color-white;
    background-color: @color-light-eggplant;

    &:hover {
      background-color: darken(@color-light-eggplant, 2%);
    }
  }

  .gameview__game-wrapper {
    position: relative;
    width: 89rem;
    margin: 5.5rem auto 5rem;
    border-radius: .3rem;
    background-clip: padding-box;
    box-shadow: 0 1.5rem 3.5rem .2rem rgba(0, 0, 0, 0.50);

    .gameview__clock {
      position: absolute;
      top: -3rem;
      text-align: right;
      width: 100%;
      padding: 0 .5rem;
      color: @color-white;
      font-size: 1.4rem;
    }

    .gameview__game {
      position: relative;
      width: 100%;
      height: 100%;
      overflow: hidden;
      border-radius: .3rem;
      background-clip: padding-box;

      .loading-animation {
        position: absolute;
        width: 100%;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: -1;
        background: rgba(204, 180, 207, 0.25);
        padding-top: 20rem;
        text-align: center;
        color: @color-white;
        font-weight: normal;
        font-style: normal;

        .loading-header {
          font-size: 2.2rem;
        }

        .loading-text {
          font-size: 1.6rem;
        }

        .loading {
          display: none;
        }
      }

      &.is-betware .loading-animation {
        // if Betware game, just always show loading
        .loading {
          display: block !important;
        }

        .not-logged-in {
          display: none !important;
        }
      }

      #sideGamesListener {
        display: none;
      }
    }
    // Special styling for mobile and tablet
    body.is-mobile-detected &, body.is-tablet-detected & {
      width: 100vw !important;
      margin: 0;

      .gameview__clock {
        position: relative;
        top: 0;
      }
    }
  }
  // when logged in, show loading instead of "not logged in" texts
  body.is-logged-in .quick-game-widget.is-microgaming .loading-animation {
    .not-logged-in {
      display: none !important;
    }

    .loading {
      display: block !important;
    }
  }
  // make sure avalon list styles does not affect game details (IU-12260)
  ul {
    padding: 0;

    li {
      margin: 0;

      &:before {
        content: "";
      }
    }
  }
}
