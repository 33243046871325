@import '../Variables/breakpoints.less';
@import '../Variables/colors.less';

.avalon-area {
  .heading {
    min-height: 3.3rem;
    padding: 0 0.1rem;

    h1.title {
      font-size: 2.2rem;
      margin: 2.6rem 0 1.6rem 0;
      color: @color-white;
      font-weight: 600;
      display: inline-block;
      text-transform: none;
      line-height: 1.4;

      @media only screen and (min-width: @breakpoint-small) {
        font-size: 2.4rem;
      }
    }

    h2.subtitle {
      font-size: 2.2rem;
      display: inline-block;
      font-weight: 300;
      color: @color-white;
      margin: auto auto auto 1rem;
      line-height: 1.4;
      text-transform: none;

      @media only screen and (min-width: @breakpoint-small) {
        font-size: 2.4rem;
      }
    }
  }

  .heading--center {
    text-align: center;
  }

}
