@import (reference) "../variables.less";

.region-quick,
.region-avalon-quick {
  .seo-text-container,
  footer .info-bar {
    background: @quick-background;
  }

  .global-footer {
    border: none;
  }
}
